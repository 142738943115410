import {
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, generatePath } from "react-router-dom";
import { ConfirmSessionAbortDialog, DialogContainer } from "../components";
import { InfoIcon } from "../components/icons";
import {
  selectSessionOverviewReceived,
  selectSessionState,
} from "../features/sessionSlice";
import {
  useActiveOrganizationQuery,
  useAppSelector,
  usePortalName,
} from "../hooks";
import { Organization } from "../hooks/types";
import { useOrganizationQuery } from "../hooks/useActiveOrganizationQuery";
import { useApplicationForApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { SESSION_STATE } from "../session/session-state";
import { terminateSession } from "../signalR";
import { routes } from "../utils/routes";
import { SessionControls } from "./session-page/SessionControls";
import { SessionPageContext } from "./session-page/SessionPageContext";
import { SessionPreview } from "./session-page/SessionPreview";

export function SessionPage() {
  const { data: organization } = useActiveOrganizationQuery();
  const session = useAppSelector(selectSessionState);
  const sessionOverviewReceived = useAppSelector(selectSessionOverviewReceived);
  const abortSessionDialogState = useDisclosure();
  const productName = usePortalName();
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );

  const abortSession = () => {
    terminateSession(session.id!);
  };

  if (!sessionOverviewReceived) {
    return (
      <Flex justifyContent={"center"} alignItems={"center"} flexGrow={1}>
        <CircularProgress isIndeterminate />
      </Flex>
    );
  }

  // if we ended the session manually (or it errored), redirect back to the application's detail page
  if (session.state >= SESSION_STATE.ENDING && app) {
    return (
      <Navigate to={generatePath(routes.application.details, { id: app.id })} />
    );
  } else if (session.state < SESSION_STATE.REQUESTED) {
    return <Navigate to={routes.home} />;
  }

  // if the session is from another organization, show a warning and allow to end the session
  if (
    organization &&
    session.id &&
    Number(session.organizationId) !== organization?.id
  ) {
    return (
      <SessionFromDifferentOrganizationWarning
        abortSession={abortSession}
        organizationId={Number(session.organizationId)}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Session {app?.name ?? ""}
          {app?.name ? " - " : ""}
          {productName}
        </title>
      </Helmet>
      {app && (
        <SessionPageContext.Provider
          value={{
            showAbortSessionDialog: abortSessionDialogState.onOpen,
            abortSession,
            application: app,
          }}
        >
          <Stack
            direction={["column", "column", "row"]}
            display="flex"
            flexGrow={{ base: session.isValid ? 0 : 1, md: 1 }}
            padding={[4, 6]}
            alignItems="normal"
            justifyContent="space-evenly"
          >
            <SessionPreview session={session} />
            {session.isValid && <SessionControls session={session} />}
          </Stack>
          <ConfirmSessionAbortDialog
            isOpen={abortSessionDialogState.isOpen}
            onClose={abortSessionDialogState.onClose}
            onConfirm={abortSession}
          />
        </SessionPageContext.Provider>
      )}
    </>
  );
}

function SessionFromDifferentOrganizationWarning({
  abortSession,
  organizationId,
}: {
  abortSession: () => void;
  organizationId: Organization["id"];
}) {
  const { t } = useTranslation();
  const organizationQuery = useOrganizationQuery(organizationId);
  const sessionUrlInOtherOrganization = useMemo(() => {
    if (!organizationQuery.data?.domain) {
      return "#";
    }

    return window.location.href.replace(
      window.location.host,
      organizationQuery.data?.domain,
    );
  }, [organizationQuery.data?.domain]);

  const translationContext = {
    organizationName: organizationQuery.data?.name,
  };

  return (
    <Box width={"container.sm"} margin="auto">
      <DialogContainer>
        <Stack spacing={4} alignItems={"center"}>
          <Icon as={InfoIcon} boxSize={20} />

          <Stack alignItems="center" textAlign={"center"}>
            <Text>
              {t("session.switch_organization_info", translationContext)}
            </Text>
          </Stack>
          <HStack justifyContent={"center"}>
            <Button
              isLoading={organizationQuery.isLoading}
              as="a"
              cursor={"pointer"}
              href={sessionUrlInOtherOrganization}
            >
              {t("session.switch_organization", translationContext)}
            </Button>
            <Button onClick={abortSession} colorScheme="red">
              {t("session.end")}
            </Button>
          </HStack>
        </Stack>
      </DialogContainer>
    </Box>
  );
}
