import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector, useNotificationToast } from "../hooks";

export const VmUpgradeNotification = () => {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const currentSession = useAppSelector(selectSessionState);
  const [vmSize, setVmSize] = useState<string | undefined>();

  useEffect(() => {
    if (!currentSession.isValid) {
      setVmSize(undefined);
      return;
    }

    if (currentSession.vmSize) {
      setVmSize(currentSession.vmSize);
    }

    if (vmSize && currentSession.vmSize && vmSize !== currentSession.vmSize) {
      setVmSize(currentSession.vmSize);
      toast({
        title: t("vmUpgradeNotification.title"),
        description: t("vmUpgradeNotification.description"),
        status: "success",
        duration: 6000,
      });
    }
  }, [currentSession, t, toast, vmSize]);

  return null;
};
