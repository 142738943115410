import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationBuildId } from "../hooks/types";
import { RootState } from "../store";

type CloudRenderingState = {
  regionsLatency: { [id: string]: number };
  configurationDialogShown: boolean;
  applicationBuildId?: ApplicationBuildId;
};

type LatencyCheck = {
  region: string;
  latencyMs: number;
};

// Define the initial state using that type
const initialState: CloudRenderingState = {
  regionsLatency: {},
  configurationDialogShown: false,
};

export const cloudRenderingSlice = createSlice({
  name: "cloud-rendering",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openConfigurationDialog: (
      state,
      action: PayloadAction<ApplicationBuildId | undefined>,
    ) => {
      state.configurationDialogShown = true;
      state.applicationBuildId = action?.payload;
    },
    closeConfigurationDialog: (state) => {
      state.configurationDialogShown = false;
    },
    addLatency: (state, action: PayloadAction<LatencyCheck>) => {
      state.regionsLatency[action.payload.region] = action.payload.latencyMs;
    },
  },
});

export const { openConfigurationDialog, closeConfigurationDialog, addLatency } =
  cloudRenderingSlice.actions;

export const selectCloudRendering = (state: RootState) => state.cloudRendering;
export const selectRegionsLatency = createSelector(
  selectCloudRendering,
  (cloudRendering) => cloudRendering.regionsLatency,
);
export default cloudRenderingSlice.reducer;
