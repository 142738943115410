import { CircularProgress } from "@chakra-ui/progress";
import { useParams } from "react-router";
import { Navigate, generatePath, useLocation } from "react-router-dom";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { routes } from "../utils/routes";
import { LaunchPage, LaunchPageParams } from "./LaunchPage";

/**
 * An adapter component for the launch page that handles the case that a launch link uses an application build id instead of an application id
 */

export function LaunchPageAdapter() {
  const {
    groupId,
    id: applicationId,
    renderMode,
    targetDeviceType,
  } = useParams<LaunchPageParams>();
  const location = useLocation();
  const isApplicationBuildId = !isNaN(Number(applicationId));

  // if application Id is a number, it's actually an application build's id, not an application id which would be a UUID
  // in this case, we need to find the underlying application id
  const applicationBuildId = Number(applicationId);
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId, {
    enabled: isApplicationBuildId,
  });

  // redirect to the launch page using the application id instead of the application build id (which is legacy)
  if (isApplicationBuildId && applicationBuildQuery.isSuccess) {
    const routeArgs = {
      id: applicationBuildQuery.data.application,
      groupId: groupId ?? null,
      renderMode: renderMode ?? null,
      targetDeviceType: targetDeviceType ?? null,
    };
    const route = groupId ? routes.launch.appViaGroup : routes.launch.app;
    return (
      <Navigate
        to={{
          ...location,
          pathname: generatePath(route, routeArgs),
        }}
      />
    );
  }

  if (!isApplicationBuildId) {
    return <LaunchPage />;
  }

  return <CircularProgress />;
}
