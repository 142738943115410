import { useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useAuthentication } from "../auth";

type FeatureFlags = {
  encryptVrStream?: boolean;
  vmImage?: string;
  debugModeEnabled?: boolean;
  virtualMachineId?: string;
  [key: string]: boolean | string | undefined;
};

export function useFeatureFlags() {
  const { user } = useAuthentication();
  const key = useMemo(() => `portal-feature-flags-${user?.id}`, [user?.id]);

  return useLocalStorage<FeatureFlags>(key, {
    encryptVrStream: undefined,
    debugModeEnabled: false,
  });
}
