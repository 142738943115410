import queryString from "query-string";
import { generatePath } from "react-router-dom";
import { ApplicationId } from "../hooks/types";
import { RenderMode, TargetDeviceType } from "../pages/launch/types";
import { SessionData, SessionType } from "../session/types";

export const routes = {
  home: "/",
  login: "/login",
  logout: "/logout",
  session: "/session",
  remoteRenderingBackchannel: "/session/remote-rendering-node-backchannel",
  launch: {
    app: "/apps/:id/launch/:renderMode/:targetDeviceType",
    appViaGroup:
      "/groups/:groupId/apps/:id/launch/:renderMode/:targetDeviceType",
    legacy: {
      app: "/apps/:id/launch",
      appViaGroup: "/groups/:groupId/apps/:id/launch",
    },
  },
  legacyConnectDevice: "/connect-device",
  connectDevice: "/connect-device",
  legacy: {
    connectHmd: "/connect-hmd",
  },
  application: {
    details: "/apps/:id",
    detailsViaGroup: "/groups/:groupId/apps/:id",
  },
  clients: {
    install: "/install-client",
    installForDevice: "/install-client/:deviceSlug",
    installForDeviceSpecificType:
      "/install-client/:deviceSlug/:installationType",
  },
} as const;

export function generateAppLaunchLink({
  appId,
  type: sessionType,
  renderRegion: regionName,
  vmSize,
  vmImage,
  launchArguments: args,
  deviceIdentifier: targetDevice,
  encryptVrStream,
  virtualMachineId,
  debugModeEnabled,
}: Pick<
  SessionData,
  | "type"
  | "renderRegion"
  | "vmSize"
  | "vmImage"
  | "launchArguments"
  | "deviceIdentifier"
> & {
  appId: ApplicationId;
  encryptVrStream?: boolean;
  virtualMachineId?: string;
  debugModeEnabled?: boolean;
}) {
  const renderMode: RenderMode = [
    SessionType.CloudRenderedNonVR,
    SessionType.CloudRenderedVR,
  ].includes(sessionType)
    ? "cloud"
    : "local";
  const targetDeviceType: TargetDeviceType =
    sessionType === SessionType.CloudRenderedNonVR
      ? "browser"
      : sessionType === SessionType.CloudRenderedVR
        ? "standalone"
        : sessionType === SessionType.LocallyRenderedWindows
          ? "windows"
          : sessionType === SessionType.LocallyRenderedStandalone
            ? "standalone"
            : "browser";

  const query = queryString.stringify(
    {
      region: regionName,
      vmSize,
      vmImage,
      args,
      targetDevice,
      // need to encode the boolean parameter as 1 if true so that it can be parsed correctly
      encryptVrStream: encryptVrStream ? "1" : undefined,
      enableDebugMode: debugModeEnabled ? "1" : undefined,
      virtualMachineId,
    },
    { skipEmptyString: true, skipNull: true },
  );
  const path = generatePath(routes.launch.app, {
    id: appId,
    renderMode,
    targetDeviceType,
  });
  return `${path}?${query}`;
}
