import { useEffect, useState } from "react";

export function useSecondsTicker(
  startSeconds: number,
  direction: -1 | 1 = -1,
  min: number = Number.MIN_SAFE_INTEGER,
  max: number = Number.MAX_SAFE_INTEGER,
  start: boolean = true,
) {
  const [seconds, setSeconds] = useState(startSeconds);

  useEffect(() => {
    if (!start) {
      setSeconds(startSeconds);
      return;
    }

    setSeconds(startSeconds);
    const interval = setInterval(() => {
      setSeconds((seconds) =>
        Math.min(Math.max(seconds + direction, min), max),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [direction, max, min, start, startSeconds]);

  return seconds;
}
