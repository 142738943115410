import { addLatency } from "./features/cloudRenderingSlice";
import { CloudRenderingRegion } from "./hooks/types";
import { getSubdomain } from "./hooks/useActiveOrganizationQuery";
import { getOrganizationDetails } from "./portal-api";
import { getOrderedCloudRenderingRegions } from "./session-management-api";
import { store } from "./store";
import { latencyLookup } from "./utils/latency-lookup";

let executions = 0;

const checkAndDispatchLatency = async (regions: CloudRenderingRegion[]) => {
  if (document.visibilityState !== "visible") {
    return;
  }

  // the latency lookups need to be made sequentially to avoid network clogging
  for (const region of regions.sort((a, b) => a.distanceM - b.distanceM)) {
    try {
      // wait a bit to avoid network cloging
      await new Promise((resolve) => setTimeout(resolve, 200));

      latencyLookup(region, (latency) => {
        // something went wrong. This can happen if a image could not be downloaded
        // because the server is not reachable
        if (latency <= 2) {
          return;
        }

        store.dispatch(
          addLatency({
            latencyMs: latency,
            region: region.name,
          }),
        );
      });
    } catch (err) {
      /* empty */
    }
  }
};

async function timeoutCallback(regions: CloudRenderingRegion[]) {
  // lets not spamm all the time
  if (document.visibilityState !== "visible") {
    return;
  }
  executions++;

  let regionsToCheck = 3;
  const allDistances0 = regions.filter((r) => r.distanceM > 0).length === 0;

  // the site is open for a while. lets start to make checks in remote locations too
  if (executions > 2) regionsToCheck = 10;

  // no distance array so we need to check all regions
  if (allDistances0) regionsToCheck = regions.length;

  await checkAndDispatchLatency(
    regions.filter((r) => !!r.latencyTestUrl).slice(0, regionsToCheck),
  );
}

export const latencyWorker = async () => {
  const t = true;
  let regions: CloudRenderingRegion[] = [];

  while (t) {
    await new Promise((f) => setTimeout(f, 5000));
    if (regions.length <= 0) {
      try {
        const subdomain = getSubdomain();
        const organization = await getOrganizationDetails(subdomain);
        regions = await getOrderedCloudRenderingRegions({
          organizationId: organization.id.toString(),
          includeDisabledRegions: false,
        });

        // we got some regions, lets start the latency checks
      } catch (err) {
        continue;
      }
    }
    await timeoutCallback(regions);
  }
};
