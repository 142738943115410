import {
  Icon,
  Radio,
  RadioGroup,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import {
  FaCaretUp as SortAscendingIcon,
  FaCaretDown as SortDescendingIcon,
} from "react-icons/fa";
import { CloudRenderingRegion, VmSizeInformation } from "../hooks/types";
import { useVmSizesQuery } from "../hooks/useVmSizesQuery";

const columnHelper = createColumnHelper<VmSizeInformation>();

const columns = [
  columnHelper.accessor("name", {
    id: "radio",
    cell: (props) => (
      <Radio
        value={props.getValue()}
        isDisabled={!props.row.original.isEnabled}
      />
    ),
    header: () => null,
    meta: {
      props: { padding: 0, paddingLeft: 4 },
    },
  }),
  columnHelper.accessor("displayName", {
    header: "Name",
  }),
  columnHelper.accessor("graphicsCardDisplayName", {
    header: "Graphics Card",
    id: "gpu",
  }),
  columnHelper.accessor("minVCpu", {
    header: "vCPU",
    cell: (props) => `≥ ${props.getValue()} cores`,
  }),
  columnHelper.accessor("minRam", {
    header: "RAM",
    cell: (props) => `≥ ${props.getValue()} GB`,
  }),
];

export function VmSizeSelector({
  region,
  onVmSizeSelected,
  defaultVmSize,
}: {
  region: CloudRenderingRegion;
  onVmSizeSelected: (vmSize: VmSizeInformation) => void;
  defaultVmSize: VmSizeInformation;
}) {
  const vmSizesQuery = useVmSizesQuery({
    select: (vmSizes) =>
      vmSizes.filter((vmSize) => region.supportedVmSizes.includes(vmSize.name)),
  });
  const table = useReactTable({
    columns: useMemo(
      () =>
        vmSizesQuery.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading...</Skeleton>,
            }))
          : columns,
      [vmSizesQuery.isLoading],
    ),
    data: useMemo(
      () =>
        vmSizesQuery.isLoading
          ? Array(5).fill({} as VmSizeInformation)
          : vmSizesQuery.data ?? [],
      [vmSizesQuery.data, vmSizesQuery.isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <RadioGroup
      onChange={(selectedVmSize) => {
        const vmSize = (vmSizesQuery.data ?? []).filter(
          (vmSize) => vmSize.name === selectedVmSize,
        )[0];
        onVmSizeSelected(vmSize);
      }}
      defaultValue={defaultVmSize.name}
      isDisabled={vmSizesQuery.isLoading}
      marginTop={3}
    >
      <TableContainer>
        <Table size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    {...(header.column.columnDef.meta?.props ?? {})}
                    onClick={header.column.getToggleSortingHandler()}
                    {...(header.column.getCanSort()
                      ? { cursor: "pointer", userSelect: "none" }
                      : {})}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {{
                      asc: <Icon as={SortAscendingIcon} />,
                      desc: <Icon as={SortDescendingIcon} />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    {...(cell.column.columnDef.meta?.props ?? {})}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </RadioGroup>
  );
}
