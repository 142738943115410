import { CircularProgress, Heading, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  FaTimesCircle as ErrorIcon,
  FaInfoCircle as InfoIcon,
} from "react-icons/fa";

export function ConnectionState({
  state,
  error,
}: {
  state?: RTCPeerConnectionState | "no-frames";
  error?: string;
}) {
  const { t } = useTranslation();

  if (state === "failed") {
    return (
      <>
        <Icon color="red.400" as={ErrorIcon} boxSize={16} />
        <Heading fontSize="xl" color="white">
          {error}
        </Heading>
      </>
    );
  } else if (state === "no-frames") {
    return (
      <>
        <Icon color="red.400" as={ErrorIcon} boxSize={16} />
        <Heading fontSize="xl" color="white">
          {t("spectator.connection.no_frames")}
        </Heading>
      </>
    );
  }

  return (
    <>
      {state === "closed" ? (
        <Icon color="white" as={InfoIcon} boxSize={16} />
      ) : (
        <CircularProgress isIndeterminate />
      )}
      <Heading fontSize="xl" color="white">
        {t(`spectator.connection.${state || "establishing"}`)}
        ...
      </Heading>
    </>
  );
}
