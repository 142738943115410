import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  CircularProgress,
  Divider,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMinVmSize, useVmSizeComparator } from "../hooks";
import { ApplicationId, VmSizeInformation } from "../hooks/types";
import { useApplicationCloudRenderingConfigurationQuery } from "../hooks/useApplicationsQuery";
import { SelectCloudRenderingRegion } from "../hooks/useCloudRenderingRegionsQuery";
import { useVmSizeQuery } from "../hooks/useVmSizesQuery";
import { VmSizeSelector } from "./VmSizeSelector";

export function RegionNotSupportedWarning({
  applicationId,
  alternativeRegion: preferredRegion,
  bestRegion: bestAvailableRegion,
  onAccept,
  onDecline,
  isOpen,
}: {
  applicationId: ApplicationId;
  isOpen: boolean;
  onAccept: (region: SelectCloudRenderingRegion) => void;
  onDecline: () => void;
  bestRegion: SelectCloudRenderingRegion;
  alternativeRegion: SelectCloudRenderingRegion;
}) {
  const { t } = useTranslation();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const vmSizeSelectionState = useDisclosure();
  const applicationPerformanceRequirementsQuery =
    useApplicationCloudRenderingConfigurationQuery(applicationId);
  const targetVmSize = useVmSizeQuery(
    applicationPerformanceRequirementsQuery.data?.vm_size ?? undefined,
  );
  const minVmSize = useMinVmSize({
    region: preferredRegion?.region,
    targetVmSize: targetVmSize?.name,
  });
  const [selectedVmSize, setSelectedVmSize] = useState<VmSizeInformation>();
  const selectedVmSizeWithDefault = selectedVmSize ?? minVmSize;
  // indicates whether the user can ignore the best available region and use their preferred region instead
  const { canOverwrite, requiresVmSizeChange } = useMemo(() => {
    let canOverwrite = false,
      requiresVmSizeChange = false;

    if (!bestAvailableRegion.region || !preferredRegion.region) {
      return { canOverwrite: false, requiresVmSizeChange: false };
    }

    if (bestAvailableRegion.region.name !== preferredRegion.region.name) {
      canOverwrite = true;
    }

    if (bestAvailableRegion.vmSize !== preferredRegion.vmSize) {
      requiresVmSizeChange = true;
    }

    return { canOverwrite, requiresVmSizeChange };
  }, [
    bestAvailableRegion.region,
    bestAvailableRegion.vmSize,
    preferredRegion.region,
    preferredRegion.vmSize,
  ]);
  // 1 --> selected vm size is larger than min vm size, -1 --> selected vm size is smaller than min vm size, 0 --> selected vm size is equal to min vm size
  const vmSizeComparator = useVmSizeComparator(
    selectedVmSizeWithDefault?.name,
    targetVmSize?.name,
  );

  let bestAvailableRegionDisplayName =
    bestAvailableRegion?.region?.displayName ?? t("launch.unknown_region");
  let preferredRegionDisplayName =
    preferredRegion?.region?.displayName ?? t("launch.unknown_region");
  if (
    bestAvailableRegion?.region &&
    preferredRegion?.region &&
    bestAvailableRegionDisplayName === preferredRegionDisplayName
  ) {
    bestAvailableRegionDisplayName += ` (${bestAvailableRegion.region.cloudProvider})`;
    preferredRegionDisplayName += ` (${preferredRegion.region.cloudProvider})`;
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onDecline}
      leastDestructiveRef={cancelRef}
      size={"2xl"}
      isCentered={true}
      // this dialog needs to be handled by the user, don't allow closing it without selecting something
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {vmSizeSelectionState.isOpen
              ? t("launch.region_requires_vm_size_change")
              : t("launch.region_incompatible")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Stack>
              {!vmSizeSelectionState.isOpen && (
                <Text>
                  {t("launch.region_incompatible_detail", {
                    bestRegion: bestAvailableRegionDisplayName,
                    preferredRegion: preferredRegionDisplayName,
                  })}
                </Text>
              )}
              {requiresVmSizeChange && (
                <>
                  {!vmSizeSelectionState.isOpen && (
                    <>
                      <Divider />
                      <Text
                        color={
                          vmSizeSelectionState.isOpen
                            ? undefined
                            : "chakra-subtle-text"
                        }
                      >
                        <Trans
                          i18nKey="launch.region_incompatible_force_continue"
                          t={t}
                        >
                          If you know what you are doing you can also{" "}
                          <Link onClick={vmSizeSelectionState.onToggle}>
                            click here
                          </Link>{" "}
                          to ignore this warning and continue using{" "}
                          {{
                            preferredRegion: preferredRegionDisplayName,
                          }}{" "}
                          at the risk of a degraded experience.
                        </Trans>
                      </Text>
                    </>
                  )}

                  {preferredRegion.region && vmSizeSelectionState.isOpen && (
                    <>
                      <Text>
                        {t("launch.select_vm_size", {
                          regionName: preferredRegionDisplayName,
                        })}
                      </Text>
                      {selectedVmSizeWithDefault ? (
                        <>
                          <VmSizeSelector
                            region={preferredRegion.region}
                            onVmSizeSelected={setSelectedVmSize}
                            defaultVmSize={selectedVmSizeWithDefault}
                          />
                          {vmSizeComparator === -1 && (
                            <Alert
                              status="warning"
                              variant="subtle"
                              marginY={3}
                            >
                              <AlertIcon />
                              {t("launch.vm_size_too_small", {
                                selectedVmSize:
                                  selectedVmSizeWithDefault?.displayName,
                                targetVmSize: targetVmSize?.displayName,
                              })}
                            </Alert>
                          )}
                        </>
                      ) : (
                        <CircularProgress isIndeterminate />
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack w="full" spacing={2} justifyContent={"end"}>
              {vmSizeSelectionState.isOpen && (
                <>
                  <Button
                    variant="outline"
                    onClick={vmSizeSelectionState.onClose}
                    fontWeight={"normal"}
                  >
                    {t("launch.back_to_recommendation")}
                  </Button>
                  <Spacer />
                </>
              )}
              <Button ref={cancelRef} colorScheme="gray" onClick={onDecline}>
                {t("launch.cancel")}
              </Button>
              {!vmSizeSelectionState.isOpen && (
                <Button
                  colorScheme="green"
                  onClick={() =>
                    onAccept({
                      ...bestAvailableRegion,
                      // we don't want to send the vm size if it's the same as the default
                      vmSize: undefined,
                    })
                  }
                >
                  {t("launch.continue", {
                    regionName: bestAvailableRegionDisplayName,
                  })}
                </Button>
              )}
              {canOverwrite && !requiresVmSizeChange && (
                <Button
                  colorScheme="yellow"
                  onClick={() =>
                    onAccept({
                      ...preferredRegion,
                      // we don't want to send the vm size if it's the same as the default
                      vmSize: undefined,
                    })
                  }
                >
                  {t("launch.force_continue", {
                    regionName: preferredRegionDisplayName,
                  })}
                </Button>
              )}
              {selectedVmSizeWithDefault && vmSizeSelectionState.isOpen && (
                <Button
                  colorScheme="green"
                  onClick={() =>
                    onAccept({
                      ...preferredRegion,
                      vmSize: selectedVmSizeWithDefault.name,
                    })
                  }
                  maxWidth={60}
                  whiteSpace={"normal"}
                >
                  {t("launch.force_continue_with_vm_size", {
                    regionName: preferredRegionDisplayName,
                    vmSize: selectedVmSizeWithDefault.displayName,
                  })}
                </Button>
              )}
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
