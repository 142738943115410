import { Alert, AlertIcon, Container } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "../auth";
import { routes } from "../utils/routes";

export function RequireUser({ children }: PropsWithChildren) {
  const { user } = useAuthentication();

  if (user) {
    return <>{children}</>;
  }

  return null;
}

export function RequireAuth({ children }: PropsWithChildren): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const location = useLocation();

  // if we are currently authenticating / authorizing, wait for it to finish before doing anything
  if (auth.isPendingAuthentication) {
    return (
      <Container>
        <Alert status="info" marginTop={20}>
          <AlertIcon />
          {t("authorization_in_progress")}
        </Alert>
      </Container>
    );
  }

  if (!auth.user) {
    return <Navigate to={routes.login} state={{ from: location }} />;
  }

  // all good, render children
  return <>{children}</>;
}
