import {
  createListenerMiddleware,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import log from "loglevel";
import type { ClientDevice, ClientDeviceData } from "../session/types";
import { DeviceType } from "../session/types";
import type { RootState } from "../store";
import { selectDesktopClientName } from "./desktopClientSlice";

interface DevicesState {
  devices: { [identifer: string]: ClientDevice };
  listReceived: boolean;
  deviceListUpdateCounter: number;
  activeDeviceIdentifier?: string;
}

interface ClientIdentificationListMessage {
  clients: ClientDeviceData[];
}

interface InstalledAppsMessage {
  apps: { id: string; identity: string }[];
  deviceIdentifier: string;
}

const activeDeviceIdentifierPersistenceKey = "portal-active-device-identifier";

function persistActiveDeviceIdentifier(deviceIdentifier?: string) {
  try {
    localStorage.setItem(
      activeDeviceIdentifierPersistenceKey,
      deviceIdentifier || "",
    );
  } catch {
    // ignore write errors
  }
}

function loadActiveDeviceIdentifier() {
  const serializedState = localStorage.getItem(
    activeDeviceIdentifierPersistenceKey,
  );
  if (!serializedState) {
    return undefined;
  }
  return serializedState;
}

// Define the initial state using that type
const initialState: DevicesState = {
  listReceived: false,
  deviceListUpdateCounter: 0,
  devices: {},
  activeDeviceIdentifier: loadActiveDeviceIdentifier(),
};

export const devicesSlice = createSlice({
  name: "devices",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearDevices: (state) => {
      state.devices = {};
      state.listReceived = false;
    },
    updateDevices: (
      state,
      action: PayloadAction<ClientIdentificationListMessage>,
    ) => {
      state.devices = action.payload.clients.reduce(function (
        map: { [identifier: string]: ClientDevice },
        obj: ClientDeviceData,
      ) {
        // check if we already know the installed applications for this device
        const installedApps =
          state.devices[obj.identifier]?.installedApps ?? [];
        const device: Omit<ClientDevice, "lastSeenDateTime"> = {
          ...obj,
          installedApps,
        };
        // We receive lastSeenDateTime as string so we are parsing it to be able to do calculations on it
        if (obj.type !== DeviceType.Remote) {
          (device as ClientDevice).lastSeenDateTime = Date.parse(
            obj.lastSeenDateTime as string,
          );
          map[device.identifier] = device as ClientDevice;
        }
        return map;
      }, {});
      state.listReceived = true;

      // auto select one of the available HMDs as the active one (if none yet)
      if (
        !state.activeDeviceIdentifier ||
        !state.devices[state.activeDeviceIdentifier]?.isOnline
      ) {
        state.activeDeviceIdentifier =
          action.payload.clients.filter((device) => device.isOnline).at(0)
            ?.identifier ?? undefined;
      }

      // remember that the state has been updated
      state.deviceListUpdateCounter += 1;
    },
    updateInstalledApps: (
      state,
      action: PayloadAction<InstalledAppsMessage>,
    ) => {
      if (!state.devices[action.payload.deviceIdentifier]) {
        log.debug(
          "Ignoring installed apps for unknown device",
          action.payload.deviceIdentifier,
        );
        return;
      }

      state.devices[action.payload.deviceIdentifier].installedApps =
        action.payload.apps.map((app) => ({
          id: Number(app.id),
          identity: app.identity,
        }));

      // remember that the state has been updated
      state.deviceListUpdateCounter += 1;
    },
    setActiveDevice: (state, action: PayloadAction<string>) => {
      state.activeDeviceIdentifier = action.payload;
    },
  },
});

export const {
  updateDevices,
  clearDevices,
  updateInstalledApps,
  setActiveDevice,
} = devicesSlice.actions;

const selectDevicesState = (state: RootState) => state.devices;

export const selectDevices = (state: RootState) =>
  selectDevicesState(state).devices;

export const selectOnlineDevices = createSelector(selectDevices, (devices) =>
  Object.values(devices)
    .filter((device) => device.isOnline)
    // sort by time of established connection, ascending
    .sort((a, b) => a.lastSeenDateTime - b.lastSeenDateTime),
);

export const selectOnlineClientDevices = createSelector(
  selectOnlineDevices,
  (devices) =>
    devices.filter(
      (device) => device.type?.includes("vr") || device.type?.includes("pc"),
    ),
);

export const selectDevicesListReceived = (state: RootState) =>
  state.devices.listReceived;

export const selectDevicesListUpdateCounter = (state: RootState) =>
  state.devices.deviceListUpdateCounter;

const selectActiveDeviceIdentifier = (state: RootState) =>
  state.devices.activeDeviceIdentifier;

export const selectActiveDevice = createSelector(
  [selectDevices, selectActiveDeviceIdentifier],
  (devices, activeDeviceIdentifier) => {
    if (activeDeviceIdentifier) {
      const device = devices[activeDeviceIdentifier];
      if (device?.isOnline) return device;
    }
    return undefined;
  },
);

export const selectIsDesktopClientConnected = createSelector(
  [selectOnlineClientDevices, selectDesktopClientName],
  (onlineDevices, desktopClientName) =>
    onlineDevices.some(
      (item) =>
        item.type === DeviceType.Desktop && item.name === desktopClientName,
    ),
);

// Middleware to save the user's cloud rendering preferences to local storage so they are still accessible
const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  actionCreator: setActiveDevice,
  effect: async (action) => {
    persistActiveDeviceIdentifier(action.payload);
  },
});
export const persistActiveDeviceMiddleware = listenerMiddleware.middleware;

export default devicesSlice.reducer;
