import axios from "axios";
import { cloudRenderingSessionManagementServiceUrl } from "../config";
import type {
  CloudRenderingRegion,
  Organization,
  RegionFilter,
  SessionRating,
  UserPreferences,
  VmSizeInformation,
} from "../hooks/types";

const sessionManagementApiClient = axios.create({
  baseURL: cloudRenderingSessionManagementServiceUrl,
});

export default sessionManagementApiClient;

export async function getUserPreferences(
  organizationId: string,
): Promise<UserPreferences> {
  const response = await sessionManagementApiClient.get<UserPreferences>(
    `/Users/Preferences`,
    {
      params: {
        organizationId,
      },
    },
  );
  return response.data;
}

export async function setUserPreferences(userPreferences: UserPreferences) {
  const response = await sessionManagementApiClient.post(
    `/Users/Preferences`,
    userPreferences,
  );
  return response.data;
}

export async function rateSession(sessionId: string, rating: SessionRating) {
  const response = await sessionManagementApiClient.patch(
    `/Sessions/${sessionId}/Rate`,
    rating,
  );
  return response.data;
}

export async function getVmSizes(): Promise<VmSizeInformation[]> {
  const response = await sessionManagementApiClient.get<{
    vmSizes: VmSizeInformation[];
  }>(`/Vm/Sizes`);
  return response.data.vmSizes;
}

export async function getOrderedCloudRenderingRegions({
  appId,
  ...filters
}: RegionFilter = {}): Promise<CloudRenderingRegion[]> {
  const response = await sessionManagementApiClient.post(`/Regions`, {
    appId: appId?.toString(),
    ...filters,
  });
  return response.data.regions;
}

interface SessionDetailsResponse {
  id: string;
  sessionCreatedDateTime: string;
  sessionTerminatedDateTime: string | null;
  experienceStartedDateTime: string | null;
}

export async function getSessionDetails(sessionId: string) {
  const response = await sessionManagementApiClient.get<SessionDetailsResponse>(
    `/Sessions/${sessionId}`,
  );
  return response.data;
}

export type PaginatedList<TType> = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: TType[];
  pageNumber: number;
  totalPages: number;
  totalResults: number;
};

export interface VmFilter {
  organizationId?: string;
  includeDestroyed?: boolean;
}

export interface VirtualMachine {
  id: string;
  region: string;
  regionDisplayName: string;
  vmSize: string;
  vmSizeDisplayName: string;
  activeUserIdentifier?: string;
  privateIp?: string;
  publicIp?: string;
  image: string;
  pulumiState: string;
  state: string;
  organizationId?: string;
  reservedInstanceId?: string;
  isStarted: boolean;
  isReady: boolean;
  isHealthy: boolean;
  debugModeEnabled: boolean;
  dateTimeCreated: string;
  firstTimeReadyDateTime?: string;
  expirationDateTime: string;
}

export type VirtualMachineList = PaginatedList<VirtualMachine>;

export async function getVirtualMachines(
  params: VmFilter,
): Promise<VirtualMachineList> {
  const response = await sessionManagementApiClient.get<VirtualMachineList>(
    "/Vm",
    {
      params: params,
    },
  );

  return response.data;
}

const cloudXREnryptionPolicyOptions = [
  "Disallowed",
  "OptIn",
  "OptOut",
  "Enforced",
] as const;

type CloudXREncryptionPolicy = (typeof cloudXREnryptionPolicyOptions)[number];

type OrganizationCloudRenderingPolicy = {
  cloudXREncryption: CloudXREncryptionPolicy;
};

export async function getOrganizationCloudRenderingPolicies(
  id: Organization["id"],
) {
  return (
    await sessionManagementApiClient.get<OrganizationCloudRenderingPolicy>(
      `/Organizations/${id}/Settings`,
    )
  ).data;
}
