import { chakra } from "@chakra-ui/react";
import PicoLogo from "../assets/img/pico-logo.svg?react";
import WaveLogo from "../assets/img/wave-logo.svg?react";

export { BsMeta as MetaIcon } from "react-icons/bs";
export {
  FaVolumeMute as AudioMutedIcon,
  FaVolumeUp as AudioUnmutedIcon,
  FaTimes as ClearIcon,
  FaCopy as CopyIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaInfoCircle as InfoIcon,
  FaLock as RequestAccessIcon,
  FaExclamationCircle as WarningIcon,
  FaWindows as WindowsIcon,
} from "react-icons/fa";

export const WaveIcon = chakra(WaveLogo);
export const PicoIcon = chakra(PicoLogo);
