import { useFeatureFlags } from ".";
import { useOrganizationCloudRenderingPolicies } from "./useOrganizationCloudRenderingPolicies";

export function useEncryptVrStreamSetting(): boolean | undefined {
  const { data: policies, isSuccess } = useOrganizationCloudRenderingPolicies();
  const [flags] = useFeatureFlags();

  if (!isSuccess && flags?.encryptVrStream === undefined) {
    return undefined;
  }

  return policies?.cloudXREncryption === "Disallowed"
    ? false
    : policies?.cloudXREncryption === "Enforced" ||
        (flags?.encryptVrStream ?? policies?.cloudXREncryption === "OptOut");
}
