import { createContext, useContext } from "react";
import { Application } from "../../hooks/types";

interface ISessionPageContext {
  showAbortSessionDialog: () => void;
  abortSession: () => void;
  application: Application;
}
export const SessionPageContext = createContext<ISessionPageContext>(
  {} as ISessionPageContext,
);

export function useSessionPageContext() {
  return useContext(SessionPageContext);
}
