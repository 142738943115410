import { useQuery } from "@tanstack/react-query";
import log from "loglevel";
import { ReactNode, useCallback, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useAuthentication } from "../auth";
import {
  cloudRenderingSessionManagementServiceUrl,
  desktopClientOAuth2ClientId,
  desktopClientOAuth2ClientSecret,
  portalBackendUrl,
} from "../config";
import {
  availableLocally,
  connectionAttemptFailed,
  connectionLost,
  handshakeCompleted,
  selectDesktopClient,
} from "../features/desktopClientSlice";
import {
  useActiveOrganizationQuery,
  useAppDispatch,
  useAppSelector,
} from "../hooks";
import { verifyDeviceAuthorizationRequest } from "../portal-api";
import { DesktopClientContext } from "./context";
import {
  Message,
  ServerHelloMessage,
  ServiceMessage,
  VerifyAuthorizationMessage,
  acknowledgeMessage,
  buildMessageHeader,
  helloMessage,
} from "./messages";

const portalBackendHost = new URL(portalBackendUrl).host;

const desktopClientWebsocketHost = "127.0.0.1";
const desktopClientWebsocketPort = 35987;
const defaultLocalConnectionPollingInterval = 5000;

export function DesktopClientProvider({ children }: { children?: ReactNode }) {
  const dispatch = useAppDispatch();
  const auth = useAuthentication();
  const { isConnected, isAvailableLocally } =
    useAppSelector(selectDesktopClient);
  const { data: organization } = useActiveOrganizationQuery();
  const [localConnectionPollingInterval, setLocalConnectionPollingInterval] =
    useState(defaultLocalConnectionPollingInterval);

  // query to poll connection to local desktop client
  useQuery<"timeout" | "error" | "success" | "abort">({
    queryKey: ["desktop-client-service"],
    queryFn: ({ signal }) => {
      return new Promise<"success">((resolve, reject) => {
        let websocketTest: WebSocket | null = null;

        const cleanupWebsocket = () => {
          if (websocketTest) {
            websocketTest.onopen = null;
            websocketTest.onerror = null;
            websocketTest.close();
          }
        };

        // if the socket doesn't connect in 150ms, DC isn't running locally
        setTimeout(() => {
          // timeout
          cleanupWebsocket();
          reject(new Error("timeout"));
        }, 150);

        signal?.addEventListener("abort", () => {
          cleanupWebsocket();
          reject(new Error("abort"));
        });

        // opening up websocket connection
        websocketTest = new WebSocket(
          `ws://${desktopClientWebsocketHost}:${desktopClientWebsocketPort}/service`,
        );
        // on error or success -> resolve
        websocketTest.onopen = () => {
          dispatch(availableLocally());
          resolve("success");
          cleanupWebsocket();
        };
        websocketTest.onerror = () => {
          reject(new Error("error"));
        };
      })
        .then((res) => {
          // reset polling interval as soon as we succeeded
          setLocalConnectionPollingInterval(
            defaultLocalConnectionPollingInterval,
          );
          return res;
        })
        .catch<"timeout" | "error" | "success" | "abort">((error: Error) => {
          // in any case, keep track of the failed connection attempt
          dispatch(connectionAttemptFailed());

          // we need to catch errors and turn them into a successful response so that refetchinterval works
          // this is a workaround for retrying the query, see https://github.com/TanStack/query/issues/7295
          if (error.message === "timeout") {
            return "timeout";
          } else if (error.message === "abort") {
            return "abort";
          } else if (error.message === "error") {
            return "error";
          }

          throw error;
        });
    },
    refetchInterval: () =>
      isAvailableLocally ? false : localConnectionPollingInterval,
  });

  // establish an unscoped (no user context) connection to see whether the desktop client is online at all
  const { sendJsonMessage, lastJsonMessage } = useWebSocket<ServiceMessage>(
    `ws://${desktopClientWebsocketHost}:${desktopClientWebsocketPort}/service`,
    {
      shouldReconnect: () => true,
      onOpen: () => {
        if (!isConnected) {
          sendJsonMessage(helloMessage);
        }
      },
      onClose: () => {
        if (isConnected) {
          dispatch(connectionLost());
        }
      },
      reconnectAttempts: Number.MAX_VALUE,
      // just one connection!
      share: true,
    },
    !!auth.user && isAvailableLocally,
  );

  const verifyAuthorization = useCallback(
    async (userCode: string) => {
      try {
        await verifyDeviceAuthorizationRequest(userCode, organization);
      } catch (error) {
        log.error("Error during device auth for desktop client: ", error);
      }
    },
    [organization],
  );

  useEffect(() => {
    if (!lastJsonMessage) return;

    const lastMessageHeader = lastJsonMessage.Header;
    switch (lastMessageHeader?.Name) {
      case Message.SERVER_HELLO:
        sendJsonMessage(acknowledgeMessage);
        dispatch(
          handshakeCompleted({
            isDeviceAuthSupported: (
              lastJsonMessage as ServerHelloMessage
            ).IncomingMessages.some(
              (item: { Name: string }) =>
                item.Name === "request_authentication",
            ),
            name: (lastJsonMessage as ServerHelloMessage).ServerName,
          }),
        );
        break;
      case Message.VERIFY_DEVICE_AUTHORIZATION_REQUEST:
        if (lastJsonMessage.BackendUrl === portalBackendHost) {
          verifyAuthorization(
            (lastJsonMessage as VerifyAuthorizationMessage).UserCode,
          );
        }
        break;
      default:
        log.debug(`Received unsupported message`, lastJsonMessage);
        break;
    }
  }, [lastJsonMessage, sendJsonMessage, dispatch, verifyAuthorization]);

  const requestAuthentication = useCallback(() => {
    sendJsonMessage({
      Header: buildMessageHeader(Message.REQUEST_AUTHENTICATION, 2),
      Name: portalBackendHost,
      BackendUrl: portalBackendUrl,
      ClientId: desktopClientOAuth2ClientId,
      ClientSecret: desktopClientOAuth2ClientSecret,
      SessionManagementUrl: `${cloudRenderingSessionManagementServiceUrl}/hub/sessions`,
    });
  }, [sendJsonMessage]);

  return (
    <DesktopClientContext.Provider
      value={{
        requestAuthentication,
        setLocalConnectionPollingInterval,
      }}
    >
      {children}
    </DesktopClientContext.Provider>
  );
}
