import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSecondsTicker } from "../hooks/useSecondsTicker";

export function TimeTicker({
  seconds: _seconds,
  direction = -1,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}: {
  seconds: number;
  direction?: 1 | -1;
  min?: number;
  max?: number;
}) {
  const { t } = useTranslation();
  const seconds = useSecondsTicker(_seconds, direction, min, max);

  // wait time can also become negative so we need to cap it
  if (seconds <= 60) {
    return (
      <>{`${seconds} ${t("session.seconds", {
        count: Math.min(Math.max(seconds, min), max),
      })}`}</>
    );
  }

  const minutes = Math.round(seconds / 60);
  return <>{`${minutes} ${t("session.minutes", { count: minutes })}`}</>;
}

export function TimeCounter({
  startDate,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}: {
  startDate: dayjs.ConfigType;
  min?: number;
  max?: number;
}) {
  const seconds = useSecondsTicker(
    dayjs().diff(startDate, "seconds"),
    1,
    min,
    max,
  );
  const duration = dayjs.duration(seconds, "seconds");

  if (duration.asHours() < 1) {
    return <>{duration.format("mm:ss")}</>;
  }

  return <>{duration.format("HH:mm:ss")}</>;
}
