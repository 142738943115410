import axios from "axios";
import { CloudRenderingRegion } from "../hooks/types";

const latencyClient = axios.create();

latencyClient.interceptors.request.use((config) => {
  config.headers["request-startTime"] = Date.now();
  return config;
});

latencyClient.interceptors.response.use((response) => {
  if (response.config.headers) {
    const start = response.config.headers["request-startTime"] as string;
    const milliseconds = Date.now() - Number(start);
    response.headers["request-duration"] = milliseconds.toString();
  }
  return response;
});

async function getAjaxLatency(url: string, callback: (number: number) => void) {
  const request = await latencyClient.get(url, { timeout: 1000 });
  callback(parseInt(request.headers["request-duration"] as string));
}

// Images get you a result even if cors is not there. Its an error but hey, we got a result.
// The only issue is that we do not know if we can actually reach the endpoint.
// Therefore we cant do this for WLZ's
function getImageLatency(url: string, callback: (number: number) => void) {
  const startTime = Date.now();
  const image = new Image();
  const a = function () {
    const endTime = Date.now();
    const timeElapsed = endTime - startTime;
    if (undefined !== callback) {
      callback(timeElapsed);
    }
  };
  image.onerror = a;
  image.onload = a;
  image.src = `${url}?_q=${startTime}`;
}

export function latencyLookup(
  cloudRegion: CloudRenderingRegion,
  callback: (number: number) => void,
) {
  if (cloudRegion.type === "Region" || cloudRegion.type === "LocalZone") {
    getImageLatency(cloudRegion.latencyTestUrl, callback);
  } else {
    getAjaxLatency(cloudRegion.latencyTestUrl, callback);
  }
}
