import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  Link,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

const baseSupportUrl =
  "https://innoactive.atlassian.net/servicedesk/customer/portal/1/group/1/create/9";

export function SessionErrorDialog({
  sessionId,
  errorMessage,
  onClose,
  ...dialogProps
}: {
  sessionId: string;
  errorMessage: string;
  onClose: () => void;
} & Omit<AlertDialogProps, "children" | "leastDestructiveRef">) {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(sessionId ?? "");
  const cancelRef = useRef(null);

  const errorReportSummary = useMemo(
    () =>
      encodeURIComponent(
        sessionId
          ? t("session.session_error_dialog.report_summary_session", {
              sessionId,
            })
          : t("session.session_error_dialog.report_summary_starting_session", {
              sessionId,
            }),
      ),
    [sessionId, t],
  );
  const errorReportDescription = useMemo(
    () =>
      encodeURIComponent(
        t("session.session_error_dialog.report_description", {
          errorMessage,
        }),
      ),
    [errorMessage, t],
  );

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isCentered
      onClose={onClose}
      {...dialogProps}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("session.session_error_dialog.title")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text as="b" paddingBottom={3}>
              {errorMessage}
            </Text>
            {!!sessionId && (
              <>
                <Divider />
                <Text paddingTop={3}>
                  {t("session.session_error_dialog.additional_info")}
                </Text>
                <Flex mb={2} paddingTop={3}>
                  <Input value={sessionId} isReadOnly />
                  <Button onClick={onCopy} ml={2}>
                    {hasCopied ? "Copied" : "Copy"}
                  </Button>
                </Flex>
              </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack>
              <Link
                _hover={{ textDecoration: "none" }}
                as={Link}
                isExternal
                href={`${baseSupportUrl}?customfield_10093=${sessionId}&summary=${errorReportSummary}&description=${errorReportDescription}`}
              >
                <Button ref={cancelRef} onClick={onClose} variant="outline">
                  {t("session.session_error_dialog.report")}
                </Button>
              </Link>
              <Button onClick={onClose}>
                {t("session.session_error_dialog.continue")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
