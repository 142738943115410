import { useQuery } from "@tanstack/react-query";
import { VmFilter, getVirtualMachines } from "../session-management-api";

export function getVirtualMachinesQueryKey(param: VmFilter = {}) {
  return ["virtualMachines", param];
}

export function useVirtualMachinesQuery(param: VmFilter = {}) {
  return useQuery({
    queryKey: getVirtualMachinesQueryKey(param),
    queryFn: () => getVirtualMachines(param),
    refetchOnMount: false,
    retry: false,
  });
}
