import {
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FaCopy as CopyIcon,
  FaPaste as PasteIcon,
  FaEllipsisV as StreamingMenuIcon,
} from "react-icons/fa";
import {
  selectInteractiveSpectator,
  setIsAudioOutputBlockedByBrowserPolicy,
} from "../../features/interactiveSpectatorSlice";
import { selectIsCloudRenderedVRSession } from "../../features/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useSessionPageContext } from "../../pages/session-page/SessionPageContext";
import { AudioMutedIcon, ExternalLinkIcon } from "../icons";
import { AudioButton } from "./AudioButton";
import { FullscreenButton } from "./FullscreenButton";
import { MicrophoneButton } from "./MicrophoneButton";
import { useRemoteUrls } from "./useRemoteUrls";

export function InteractiveSpectatorControls({
  toggleAudioOutput,
  toggleAudioInput,
  toggleFullscreen,
  copyToClipboard,
  pasteToRemote,
}: {
  toggleAudioOutput: () => void;
  toggleAudioInput: () => void;
  toggleFullscreen: () => void;
  copyToClipboard: () => void;
  pasteToRemote: () => void;
}) {
  const { application } = useSessionPageContext();
  const remoteUrls = useRemoteUrls(application.id);
  const dispatch = useAppDispatch();
  const isMicrophoneSupported = useMemo(() => !!navigator.mediaDevices, []);
  const {
    isMicrophoneEnabled,
    isMicrophoneMuted,
    isAudioMuted,
    isFullscreen,
    isConnected,
    isAudioOutputBlockedByBrowserPolicy,
  } = useAppSelector(selectInteractiveSpectator);
  const { t } = useTranslation();
  const isCloudRenderedVRSession = useAppSelector(
    selectIsCloudRenderedVRSession,
  );

  return (
    <>
      <Popover
        isOpen={isAudioOutputBlockedByBrowserPolicy}
        onClose={() => dispatch(setIsAudioOutputBlockedByBrowserPolicy(false))}
        closeOnBlur={false}
        closeOnEsc={false}
        placement="bottom"
      >
        <PopoverAnchor>
          <AudioButton
            isAudioMuted={isAudioMuted}
            toggleAudioOutput={toggleAudioOutput}
            isDisabled={!isConnected}
          />
        </PopoverAnchor>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize={"sm"}>
            {t("spectator.audio.blocked_by_browser_policy")}
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent={"end"}>
              <Button
                leftIcon={<Icon as={AudioMutedIcon} />}
                size="xs"
                onClick={toggleAudioOutput}
              >
                Unmute
              </Button>
              <Button
                size="xs"
                onClick={() =>
                  dispatch(setIsAudioOutputBlockedByBrowserPolicy(false))
                }
                variant={"ghost"}
              >
                Ignore
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      {!isCloudRenderedVRSession && (
        <MicrophoneButton
          isMicrophoneEnabled={isMicrophoneEnabled}
          isMicrophoneMuted={isMicrophoneMuted}
          isMicrophoneSupported={isMicrophoneSupported}
          toggleAudioInput={toggleAudioInput}
          isDisabled={!isConnected}
        />
      )}
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<StreamingMenuIcon />}
          isDisabled={!isConnected}
        />
        <MenuList>
          <MenuItem
            icon={<CopyIcon />}
            onClick={copyToClipboard}
            command="Ctrl + C"
            isDisabled={!isConnected}
          >
            {t("spectator.actions.copy")}
          </MenuItem>
          <MenuItem
            icon={<PasteIcon />}
            onClick={pasteToRemote}
            command="Ctrl + V"
            isDisabled={!isConnected}
          >
            {t("spectator.actions.paste")}
          </MenuItem>
          {remoteUrls.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup title={t("spectator.remoteUrls.title")}>
                {remoteUrls.map(({ url }, idx) => (
                  <MenuItem
                    key={url + idx}
                    as={Link}
                    href={url}
                    isExternal
                    title={url}
                  >
                    <Icon boxSize={3} as={ExternalLinkIcon} marginRight={2} />{" "}
                    {limitStringLength(url, 40)}
                  </MenuItem>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
      <FullscreenButton
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
}

function limitStringLength(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}
