import {
  Box,
  Button,
  ButtonProps,
  Progress,
  ProgressProps,
} from "@chakra-ui/react";

export const LinearProgressButton = ({
  value,
  max,
  onClick,
  isLoading,
  children,
}: Pick<ButtonProps, "onClick" | "children" | "isLoading"> &
  Pick<ProgressProps, "value" | "max">) => {
  return (
    <Box position="relative" width="fit-content">
      <Button
        onClick={onClick}
        isLoading={isLoading}
        width="100%"
        position="relative"
        zIndex="1"
      >
        {children}
      </Button>
      {value !== 0 && (
        <Progress
          value={value}
          max={max}
          position="absolute"
          bottom="0"
          width="100%"
          height="10%"
          zIndex="0"
          borderRadius="md"
        />
      )}
    </Box>
  );
};
