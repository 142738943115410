import { useApplicationsQuery } from "./useApplicationsQuery";

const searchResultsPageSize = 7;

export function useSearchApplicationQuery(
  searchText = "",
  pageSize = searchResultsPageSize,
) {
  return useApplicationsQuery({
    fulltext_search: searchText,
    page_size: pageSize,
  });
}
