import {
  Flex,
  FlexProps,
  HStack,
  Heading,
  Icon,
  Link,
  Stack,
  VStack,
  chakra,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { orientation } from "o9n";
import React, { useCallback, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { FaInfoCircle as InfoIcon } from "react-icons/fa";
import "webrtc-adapter";
import { forceRelayForIce, iceServers } from "../../config";
import {
  addPressedKeyCode,
  disableAudioOutput,
  disableMicrophone,
  enableAudioOutput,
  enableMicrophone,
  removePressedKeyCode,
  resetDownedMouseButtons,
  resetPressedKeyCodes,
  selectInteractiveSpectator,
  setConnectionState,
  setFullscreen,
  setIsAudioOutputBlockedByBrowserPolicy,
  setIsNotReceivingFrames,
  toggleAudioOutput,
  toggleMicrophone,
  updateDownedMouseButton,
} from "../../features/interactiveSpectatorSlice";
import { selectRenderingServerIp } from "../../features/sessionSlice";
import {
  useAppDispatch,
  useAppSelector,
  useNotificationToast,
} from "../../hooks";
import { isIOS, isMobile } from "../../utils/browser-support";
import { DragHandle } from "../DragHandle";
import { ConnectionStateOverlay } from "./ConnectionStateOverlay";
import { InteractiveSpectatorControls } from "./InteractiveSpectatorControls";
import { RemoteUrlOpenerOverlay } from "./RemoteUrlOpenerOverlay";
import {
  LocalWebsocketSignaller,
  Signaller,
  SignallerEventType,
  SignallerInterface,
} from "./Signaller";
import { ActionType, CommandType, keys } from "./capture-daemon-protocol";
import { ButtonAction, Coordinates2D, MouseButton } from "./constants";
import {
  discardEvent,
  getCaptureDaemonMouseEvent,
  getCaptureDaemonWheelEvent,
  getCoordiantesFromMouseEvent,
  getCoordinatesFromTouchEvent,
  getCoordinatesRelativeToVideo,
  isSupportedButtonEvent,
  stopEventPropagation,
} from "./helpers";

const MULTITOUCH_WAIT_DELAY_MILLISECONDS = 50;

const isMultiTouchEvent = (event: React.TouchEvent) => {
  // for touchstart event, we need to check the complete list of touches not just changed ones,
  // see https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent/changedTouches
  if (event.type === "touchstart") {
    return event.touches.length > 1;
  }
  return event.changedTouches.length > 1;
};

// https://w3c.github.io/webrtc-pc/#dom-rtcconfiguration
const peerConfiguration: RTCConfiguration = {
  iceServers,
  iceTransportPolicy: forceRelayForIce ? "relay" : "all",
};

const Video = chakra("video");

interface InteractiveSpectatorProps {
  width?: string | number;
  height?: string | number;
  sessionId?: string;
}

export function InteractiveSpectator({
  width,
  height,
  sessionId,
  ...props
}: InteractiveSpectatorProps & FlexProps) {
  const video = useRef<HTMLVideoElement>(null);
  const fullscreenContainerRef = useRef<HTMLDivElement>(null);
  const dataChannel = useRef<RTCDataChannel | null>(null);
  const audioStream = useRef<MediaStream | null>(null);
  const {
    isMicrophoneEnabled,
    isMicrophoneMuted,
    isFullscreen,
    isAudioMuted,
    isConnected,
    isNotReceivingFrames,
    pressedKeyCodes,
    downedMouseButtonIds,
  } = useAppSelector(selectInteractiveSpectator);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const multiTouchTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const toast = useNotificationToast();
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const serverIpAddress = useAppSelector(selectRenderingServerIp);

  const sendMousePositionToCaptureDaemon = useCallback(
    (coords: Coordinates2D) => {
      dataChannel.current?.send(
        JSON.stringify({
          t: CommandType.MousePosition,
          ...coords,
        }),
      );
    },
    [],
  );

  const sendMouseEventToCaptureDaemon = useCallback(
    (
      buttonId: number,
      type: "up" | "down" | undefined,
      coords: Coordinates2D,
    ) => {
      if (!type) {
        log.error("unsupported event type", type);
      } else {
        dispatch(updateDownedMouseButton({ buttonId, change: type }));
      }
      // ensure to also update the mouse position prior to clicking
      sendMousePositionToCaptureDaemon(coords);
      dataChannel.current?.send(
        JSON.stringify(getCaptureDaemonMouseEvent({ button: buttonId, type })),
      );
    },
    [dispatch, sendMousePositionToCaptureDaemon],
  );

  const sendWheelEventToCaptureDaemon = useCallback((deltaY: number) => {
    dataChannel.current?.send(
      JSON.stringify(getCaptureDaemonWheelEvent(deltaY)),
    );
  }, []);

  const handleTouchStart = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current || dataChannel.current?.readyState !== "open") {
        return;
      }

      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );

      // multitouch --> simulate button 2 / right mouse button down
      sendMouseEventToCaptureDaemon(
        isMultiTouchEvent(event) ? MouseButton.Right : MouseButton.Left,
        ButtonAction.Down,
        relativeCoords,
      );
    },
    [sendMouseEventToCaptureDaemon],
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      // if we're dealing with a touch event and we just started touching, delay the rest of the logic a bit so we
      // can decide whether it will become a multitouch or not
      if (multiTouchTimeoutId.current || isMultiTouchEvent(event)) {
        if (multiTouchTimeoutId.current) {
          clearTimeout(multiTouchTimeoutId.current);
        }
        multiTouchTimeoutId.current = undefined;
        handleTouchStart(event);
      } else {
        multiTouchTimeoutId.current = setTimeout(() => {
          multiTouchTimeoutId.current = undefined;
          handleTouchStart(event);
        }, MULTITOUCH_WAIT_DELAY_MILLISECONDS);
      }

      discardEvent(event);
    },
    [handleTouchStart],
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current || dataChannel.current?.readyState !== "open") {
        return;
      }
      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      // don't send invalid coordiantes
      if (relativeCoords.x === -1 || relativeCoords.y === -1) return;
      sendMousePositionToCaptureDaemon(relativeCoords);
      stopEventPropagation(event);
    },
    [sendMousePositionToCaptureDaemon],
  );

  const onTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      if (!video.current || dataChannel.current?.readyState !== "open") {
        return;
      }

      const coords = getCoordinatesFromTouchEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );

      // release the pressed mouse button (whatever it is)
      downedMouseButtonIds.forEach((buttonId) =>
        sendMouseEventToCaptureDaemon(
          buttonId,
          ButtonAction.Up,
          relativeCoords,
        ),
      );

      discardEvent(event);
    },
    [sendMouseEventToCaptureDaemon, downedMouseButtonIds],
  );

  const onMouseEvent = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!video.current || dataChannel.current?.readyState !== "open") return;
      if (isSupportedButtonEvent(event) === false) return;

      const coords = getCoordiantesFromMouseEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      sendMouseEventToCaptureDaemon(
        event.button,
        event.type === "mousedown"
          ? "down"
          : event.type === "mouseup"
            ? "up"
            : undefined,
        relativeCoords,
      );
      discardEvent(event);
    },
    [sendMouseEventToCaptureDaemon],
  );

  const onWheelEvent = useCallback(
    (event: React.WheelEvent<HTMLElement>) => {
      if (!video.current || dataChannel.current?.readyState !== "open") return;
      if (isSupportedButtonEvent(event) === false) return;
      sendWheelEventToCaptureDaemon(event.deltaY);
      discardEvent(event);
    },
    [sendWheelEventToCaptureDaemon],
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!video.current || dataChannel.current?.readyState !== "open") {
        return;
      }
      const coords = getCoordiantesFromMouseEvent(event);
      const relativeCoords = getCoordinatesRelativeToVideo(
        coords,
        video.current,
      );
      // don't send invalid coordiantes
      if (relativeCoords.x === -1 || relativeCoords.y === -1) return;
      sendMousePositionToCaptureDaemon(relativeCoords);
    },
    [sendMousePositionToCaptureDaemon],
  );

  const pasteToRemote = useCallback(() => {
    navigator.clipboard
      .readText()
      .then((value) => {
        dataChannel.current?.send(
          JSON.stringify({
            action: ActionType.Paste,
            value: value,
          }),
        );
      })
      .catch(async () => {
        toast({
          title: t("spectator.clipboard.error.missing-permission-title"),
          description: t(
            "spectator.clipboard.error.missing-permission-description",
          ),
          status: "warning",
        });
      });
  }, [toast, t]);

  const onKeyDown = useCallback(
    (code: string) => {
      if (dataChannel.current?.readyState !== "open") return;

      if (
        code === "KeyV" &&
        (pressedKeyCodes.includes("ControlLeft") ||
          pressedKeyCodes.includes("CommandLeft"))
      ) {
        pasteToRemote();
        return;
      }

      const key = keys[code];
      dispatch(addPressedKeyCode(code));
      key &&
        dataChannel.current.send(
          JSON.stringify({
            t: CommandType.KeyDown,
            v: keys[code],
          }),
        );
    },
    [dispatch, pressedKeyCodes, pasteToRemote],
  );

  const onKeyDownReact = useCallback(
    (event: React.KeyboardEvent) => {
      onKeyDown(event.code);
      discardEvent(event);
    },
    [onKeyDown],
  );

  const onKeyUp = useCallback(
    (code: string) => {
      if (dataChannel.current?.readyState !== "open") return;

      const key = keys[code];
      dispatch(removePressedKeyCode(code));
      key &&
        dataChannel.current.send(
          JSON.stringify({
            t: CommandType.KeyUp,
            v: keys[code],
          }),
        );
    },
    [dispatch],
  );

  const onKeyUpReact = useCallback(
    (event: React.KeyboardEvent) => {
      onKeyUp(event.code);
      discardEvent(event);
    },
    [onKeyUp],
  );

  const onFocusLost = useCallback(
    (event: React.UIEvent) => {
      isConnected && video.current?.blur();

      if (dataChannel.current?.readyState !== "open") return;

      // release all pressed keyboard buttons
      pressedKeyCodes.forEach((code) => {
        keys[code] &&
          dataChannel.current?.send(
            JSON.stringify({ t: CommandType.KeyUp, v: keys[code] }),
          );
      });

      // release all downed mouse buttons
      downedMouseButtonIds.forEach((downedButton) => {
        dataChannel.current?.send(
          JSON.stringify(
            getCaptureDaemonMouseEvent({
              button: downedButton,
              type: "up",
            }),
          ),
        );
      });
      // reset mouse position to center
      sendMousePositionToCaptureDaemon({ x: 0.5, y: 0.5 });
      dispatch(resetDownedMouseButtons());
      dispatch(resetPressedKeyCodes());
      stopEventPropagation(event);
    },
    [
      isConnected,
      pressedKeyCodes,
      downedMouseButtonIds,
      sendMousePositionToCaptureDaemon,
      dispatch,
    ],
  );

  useEffect(() => {
    if (video.current === null) {
      return;
    }

    // initialize a new media stream to be displayed in the video
    const remoteStream = new MediaStream();
    const _video = video.current;
    _video.srcObject = remoteStream;

    // initialize Signaller
    let signaller: SignallerInterface;
    if (import.meta.env.VITE_LOCAL_INTERACTIVE_SPECTATOR_SIGNALLING) {
      log.info("Using local signalling server for interactive spectator.");
      signaller = new LocalWebsocketSignaller();
    } else {
      if (!sessionId || !serverIpAddress) {
        return;
      }
      signaller = new Signaller(sessionId, serverIpAddress);
    }

    // initialize a new peer connection
    peerConnection.current = new RTCPeerConnection(peerConfiguration);

    if (isMicrophoneEnabled) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          audioStream.current = stream;
          stream.getTracks().forEach((track) => {
            peerConnection.current?.addTrack(track);
          });
          signaller.start();
        })
        .catch(function (err) {
          log.warn(err.message);
          dispatch(disableMicrophone());
          toast({
            title: t("spectator.microphone.failed"),
            description: t("spectator.microphone.unmuting-failed"),
            status: "error",
          });
        });
      dispatch(setConnectionState("new"));
    } else {
      signaller.start();
      // when signalling starts, we already want to be in connecting state
      dispatch(setConnectionState("connecting"));
    }

    // try to autoplay the video
    _video.muted = false;
    dispatch(enableAudioOutput());
    const autoPlayAttemptPromise = _video.play();

    if (!autoPlayAttemptPromise) {
      log.warn("Autoplay failed.");
    }

    autoPlayAttemptPromise
      .then(() => {
        // Autoplay started!
        log.debug("Autoplay started.");
      })
      .catch((error) => {
        // Autoplay was prevented.
        log.debug("Autoplay was prevented. Muting video.", error);
        dispatch(disableAudioOutput());
        _video.muted = true;
        return _video
          .play()
          .then(() => {
            // Autoplay started!
            log.debug("Autoplay started with muted video.");
            dispatch(setIsAudioOutputBlockedByBrowserPolicy(true));
          })
          .catch((error) => {
            log.error(
              "Failed to start video playback even if video muted.",
              error,
            );
          });
      });

    peerConnection.current?.addEventListener("track", (event) => {
      remoteStream.addTrack(event.track);
    });

    peerConnection.current?.addEventListener("connectionstatechange", () => {
      if (peerConnection.current) {
        dispatch(setConnectionState(peerConnection.current.connectionState));
        if (peerConnection.current.connectionState === "failed") {
          Sentry.captureException(new Error("Failed to connect."), {
            extra: {
              offer: offerReceived,
              answer: answerSent,
            },
          });
        }
      }
    });

    let offerReceived: RTCSessionDescriptionInit;
    let answerSent: RTCSessionDescriptionInit;
    const iceCandidates: RTCIceCandidate[] = [];

    peerConnection.current?.addEventListener(
      "icecandidate",
      (evt) => evt.candidate && signaller.sendIceCandidate(evt.candidate),
      false,
    );

    peerConnection.current?.addEventListener("icecandidateerror", (evt) => {
      const e = evt as RTCPeerConnectionIceErrorEvent;
      log.warn("IceCandidate ecountered error code: " + e.errorCode, e);
    });

    // open a data channel to send keyboard and mouse input data
    dataChannel.current = peerConnection.current?.createDataChannel("control");
    dataChannel.current.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (data.action && data.action === ActionType.Copy && data.value) {
        navigator.clipboard.writeText(data.value);
      }
    };

    // start signalling
    signaller
      .on(SignallerEventType.IceCandidateReceived, (iceCandidate) => {
        if (peerConnection.current?.remoteDescription) {
          peerConnection.current.addIceCandidate(iceCandidate).catch(log.debug);
        } else {
          iceCandidates.push(iceCandidate);
        }
      })
      .on(SignallerEventType.OfferReceived, async (sdp) => {
        if (!peerConnection.current) {
          return;
        }

        offerReceived = sdp;
        await peerConnection.current
          .setRemoteDescription(
            new RTCSessionDescription({
              type: "offer",
              sdp: sdp["answer"],
            }),
          )
          .catch((error) => {
            Sentry.captureException(
              new Error("Failed to set remote description, error: " + error),
              {
                extra: {
                  offer: offerReceived,
                  answer: answerSent,
                },
              },
            );
          });
        const answer = await peerConnection.current.createAnswer();
        answerSent = answer;
        await peerConnection.current?.setLocalDescription(answer);
        signaller.sendOfferAnswer(answer);

        // add any ice candidates we might have already received
        iceCandidates.forEach((candidate) =>
          peerConnection.current?.addIceCandidate(candidate).catch(log.debug),
        );
      })
      .on(SignallerEventType.ConnectionError, (error) => {
        dispatch(setConnectionState("failed"));
        log.error(error);
      });

    return () => {
      if (audioStream.current !== null) {
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      signaller.stop();
      if (peerConnection.current?.connectionState !== "new") {
        peerConnection.current?.close();
        dispatch(setConnectionState("closed"));
      }
      dataChannel.current = null;
      _video.srcObject = null;
    };
  }, [sessionId, isMicrophoneEnabled, toast, t, dispatch, serverIpAddress]);

  useEffect(() => {
    let secondsWithoutFrames = 0;
    const interval = setInterval(() => {
      if (isConnected) {
        peerConnection.current?.getStats().then((stats) => {
          stats.forEach((stat) => {
            // Check if we are receiving video frames and adopt the state accordingly.
            if (stat.type === "inbound-rtp" && stat.kind === "video") {
              // Frames received are only total frames received since the connection was established.
              if (stat.framesReceived === 0) {
                if (secondsWithoutFrames === 3) {
                  !isNotReceivingFrames &&
                    dispatch(setIsNotReceivingFrames(true));
                } else {
                  secondsWithoutFrames++;
                }
              } else {
                // As soon as we get frames we can reset and stop.
                isNotReceivingFrames &&
                  dispatch(setIsNotReceivingFrames(false));
                secondsWithoutFrames = 0;
              }
            }
          });
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (isNotReceivingFrames) {
        dispatch(setIsNotReceivingFrames(false));
      }
    };
  }, [isConnected, isNotReceivingFrames, dispatch]);

  useEffect(() => {
    if (isConnected) {
      peerConnection.current?.getStats().then((stats) => {
        let localCandidateId = "";
        let remoteCandidateId = "";
        stats.forEach((stat) => {
          if (stat.type === "candidate-pair" && stat.nominated === true) {
            localCandidateId = stat.localCandidateId;
            remoteCandidateId = stat.remoteCandidateId;
          }
        });
        stats.forEach((stat) => {
          if (stat.id === localCandidateId) {
            log.info("Local candidate", stat);
          } else if (stat.id === remoteCandidateId) {
            log.info("Remote candidate", stat);
          }
        });
      });
    }
  }, [isConnected]);

  const copyToClipboard = useCallback(() => {
    const wasControlDown = pressedKeyCodes.includes("ControlLeft");
    const wasKeyCDown = pressedKeyCodes.includes("KeyC");

    onKeyDown("ControlLeft");
    onKeyDown("KeyC");

    if (wasKeyCDown) onKeyUp("KeyC");
    if (wasControlDown) onKeyUp("ControlLeft");
  }, [onKeyDown, onKeyUp, pressedKeyCodes]);

  const toggleAudioInput = useCallback(() => {
    if (!isMicrophoneEnabled) {
      // microphone is currently not enabled, we'll reconnect to enable it
      dispatch(enableMicrophone());
    } else if (audioStream.current !== null) {
      audioStream.current.getTracks().forEach((track) => {
        track.enabled = isMicrophoneMuted;
      });
      dispatch(toggleMicrophone());
    }
  }, [dispatch, isMicrophoneEnabled, isMicrophoneMuted]);

  const toggleFullscreen = useCallback(() => {
    // on some devices, like mobile devices the real browser fullscreen has some limitations, e.g. on the type of interactions to be performed
    // special handling on iOS as fullscreen mode is not great there for interactivity
    if (isIOS) {
      if (!isFullscreen) {
        // if we're not in fullscreen mode, we'll just maximize the video, but only if we're in landscape orientation (only for mobile)
        if (!isMobile || orientation.type.startsWith("landscape")) {
          dispatch(setFullscreen(true));
        } else {
          toast({
            title: t("spectator.fullscreen.turn-to-enable-title"),
            description: t("spectator.fullscreen.turn-to-enable-description"),
            status: "info",
            duration: 5000,
          });
        }
      } else {
        if (!isMobile || orientation.type.startsWith("portrait")) {
          dispatch(setFullscreen(false));
        } else {
          toast({
            title: t("spectator.fullscreen.turn-to-disable-title"),
            description: t("spectator.fullscreen.turn-to-disable-description"),
            status: "info",
            duration: 5000,
          });
        }
      }
      return;
    }

    const _video = video.current;
    if (!_video) {
      return;
    }
    if (!isFullscreen) {
      if (_video.hasAttribute("controls")) {
        _video.removeAttribute("controls");
      }

      const container = fullscreenContainerRef.current;
      if (!document.fullscreenElement && container) {
        container.requestFullscreen().then(() => dispatch(setFullscreen(true)));
      }
      document.onkeydown = (event: KeyboardEvent) => {
        onKeyDown(event.code);
      };
      document.onkeyup = (event: KeyboardEvent) => {
        onKeyUp(event.code);
      };
      document.onfullscreenchange = () => {
        if (!document.fullscreenElement) {
          document.onkeydown = null;
          document.onkeyup = null;
          dispatch(setFullscreen(false));
        }
      };
    } else {
      document.exitFullscreen().then(() => dispatch(setFullscreen(false)));
    }
  }, [dispatch, isFullscreen, onKeyDown, onKeyUp, t, toast]);

  // automatically enable fullscreen mode on mobile devices when screen orientation changes to landscape mode
  useEffect(() => {
    const onOrientationChange = () => {
      // only do this on mobile devices, not tablets or desktops
      if (!isMobile) return;

      toggleFullscreen();
    };
    orientation.addEventListener("change", onOrientationChange);
    return () => {
      orientation.removeEventListener("change", onOrientationChange);
    };
  }, [toast, toggleFullscreen]);

  const isNonNativeFullscreen = isIOS && isFullscreen;

  return (
    <>
      <Flex
        css={
          isNonNativeFullscreen
            ? {
                height: ["100vh", "100dvh"],
                width: ["100vw", "100dvw"],
                top: 0,
                left: 0,
              }
            : undefined
        }
        width={width}
        height={height}
        position={isNonNativeFullscreen ? "fixed" : "relative"}
        zIndex={isNonNativeFullscreen ? "overlay" : undefined}
        bgColor={"chakra-body-bg"}
        flexDirection="column"
        justifyContent={["initial", "initial", "center"]}
        // prevent long touch press to bring up a context menu
        sx={{
          WebkitTouchCallout: "none !important",
          WebkitUserSelect: "none !important",
        }}
        {...props}
      >
        <Flex
          ref={fullscreenContainerRef}
          position={
            isFullscreen ? "relative" : isConnected ? undefined : "absolute"
          }
          justifyContent={["initial", "initial", "center"]}
          flexDirection="column"
          height={"full"}
          width={"full"}
          minHeight={{ base: 32, md: undefined }}
          sx={{ touchAction: "none" }}
        >
          <Flex // min width and height are required for correct flex positioning
            minHeight={0}
            minWidth={0}
            objectFit="contain"
            position="relative"
            flexDirection="column"
          >
            <Video
              ref={video}
              muted={isAudioMuted}
              display={isConnected ? "block" : "none"}
              // to ensure the video can play without maximizing it on iPhones, add the playsinline property, see https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari#3030250
              playsInline
              tabIndex={-1}
              onMouseUp={onMouseEvent}
              onMouseMove={onMouseMove}
              onMouseDown={onMouseEvent}
              onWheel={onWheelEvent}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              onContextMenu={discardEvent}
              onClick={discardEvent}
              onKeyDown={onKeyDownReact}
              onKeyUp={onKeyUpReact}
              onMouseEnter={() => isConnected && video.current?.focus()}
              onMouseLeave={onFocusLost}
              outline="none"
            />

            <RemoteUrlOpenerOverlay
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={1}
            />
          </Flex>

          <ConnectionStateOverlay
            display={isConnected ? "none" : "flex"}
            position={isConnected ? "absolute" : "relative"}
          />

          <Flex
            width="full"
            height="full"
            zIndex={1}
            display={isNotReceivingFrames && isConnected ? "flex" : "none"}
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <VStack spacing={2} textAlign="center">
              <Icon zIndex={1} color="gray" as={InfoIcon} boxSize={16} />
              <Heading zIndex={1} fontSize="xl" color="gray">
                {t(`spectator.connection.no_frames`)}
              </Heading>
              <Heading zIndex={1} fontSize="l" color="gray">
                {t(`spectator.connection.no_frames_hint`)}
                <Link
                  href="https://innoactive.io/connection-issues"
                  target="_blank"
                >
                  https://innoactive.io/connection-issues
                </Link>
              </Heading>
            </VStack>
          </Flex>

          {isFullscreen ? (
            <Draggable
              bounds="body"
              handle=".handle"
              axis={isMobile ? "y" : "x"}
              defaultPosition={{ y: isMobile ? -50 : 0, x: 0 }}
            >
              <Stack
                position="absolute"
                right={0}
                bottom={0}
                padding={"1"}
                borderRadius={"4"}
                bgColor={"backgroundAlpha.700"}
                zIndex={1}
                direction={isMobile ? "column" : "row"}
              >
                <DragHandle
                  className="handle"
                  onTouchEnd={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                />
                <InteractiveSpectatorControls
                  toggleAudioInput={toggleAudioInput}
                  toggleAudioOutput={() => dispatch(toggleAudioOutput())}
                  toggleFullscreen={toggleFullscreen}
                  copyToClipboard={copyToClipboard}
                  pasteToRemote={pasteToRemote}
                />
              </Stack>
            </Draggable>
          ) : (
            <HStack
              display={isConnected && !isNotReceivingFrames ? "flex" : "none"}
              paddingTop={2}
              paddingX={undefined}
              justifyContent="end"
            >
              <InteractiveSpectatorControls
                toggleAudioInput={toggleAudioInput}
                toggleAudioOutput={() => dispatch(toggleAudioOutput())}
                toggleFullscreen={toggleFullscreen}
                copyToClipboard={copyToClipboard}
                pasteToRemote={pasteToRemote}
              />
            </HStack>
          )}
        </Flex>
      </Flex>
    </>
  );
}
