import {
  Alert,
  AlertIcon,
  AlertTitle,
  ChakraProvider,
  Flex,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AuthContext,
  AuthProvider,
  IAuthContext,
} from "react-oauth2-code-pkce";
import { getOrganizationSelectionUrl } from ".";
import { ColorModeConfiguration } from "../ColorModeConfiguration";
import { ExternalRedirect } from "../components/ExternalRedirect";
import { useActiveOrganizationQuery } from "../hooks";
import portalApiClient, { getCurrentUserDetails } from "../portal-api";
import sessionManagementApiClient from "../session-management-api";
import { AuthenticationContext } from "./AuthenticationContext";
import { useAuthConfig } from "./useAuthConfig";

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const {
    error,
    token,
    logIn: login,
    loginInProgress,
  }: IAuthContext = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { data: organization } = useActiveOrganizationQuery();

  useEffect(() => {
    if (!token) return;

    // ensure the token is sent with requests to portal and sm api
    [portalApiClient, sessionManagementApiClient].forEach((apiClient) => {
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    });

    // handle 401s
    const myInterceptor = portalApiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        // If we're unauthorized, user must login again (but only do this if not yet refreshing)
        if (error?.response?.status === 401) {
          login();
        }
        throw error;
      },
    );

    return () => {
      portalApiClient.interceptors.response.eject(myInterceptor);
    };
  }, [token, login, queryClient]);

  const {
    data: user,
    error: loadUserDetailsError,
    isLoading: isPendingAuthentication,
    isSuccess,
  } = useQuery({
    queryKey: ["current-user"],
    queryFn: getCurrentUserDetails,
    enabled: !!token && !!organization,
    retry: false,
  });
  const isUserPartOfOrganization =
    user &&
    organization &&
    user.organizations.find((org) => org.id === organization.id);

  if (isSuccess && !isUserPartOfOrganization) {
    // the user is not part of the active organization, go to backend to select one the user is actually part of
    return (
      <ExternalRedirect
        url={getOrganizationSelectionUrl(window.location.pathname)}
      />
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        error: loadUserDetailsError?.message ?? error ?? undefined,
        user,
        isPendingAuthentication: isPendingAuthentication || loginInProgress,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function AuthProviderWrapper({ children }: { children: ReactNode }) {
  const { data: authConfig, error } = useAuthConfig();
  const { t } = useTranslation();

  if (error && error.code !== "ECONNABORTED" && !authConfig) {
    // redirect to portal backend to identify organization and then come back to frontend afterwards.
    let redirectUrl = window.location.pathname;
    if (window.location.search) {
      redirectUrl += "?" + window.location.search;
    }
    return <ExternalRedirect url={getOrganizationSelectionUrl(redirectUrl)} />;
  }

  if (!authConfig) {
    return (
      <ChakraProvider>
        <ColorModeConfiguration />
        <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          <Alert status="loading" maxW={"container.sm"}>
            <AlertIcon />
            <AlertTitle>{t("login.identifying_organization")}</AlertTitle>
          </Alert>
        </Flex>
      </ChakraProvider>
    );
  }

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>;
}
