import { useQuery } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import type { UserPreferences } from "../hooks/types";
import { getUserPreferences } from "../session-management-api";
import { useActiveOrganizationQuery } from "./useActiveOrganizationQuery";

export function useUserPreferences() {
  const { data: organization } = useActiveOrganizationQuery();
  const { user } = useAuthentication();

  return useQuery<UserPreferences>({
    queryKey: ["user-preferences"],
    queryFn: async () => getUserPreferences(organization?.id?.toString() ?? ""),
    retry: false,
    enabled: !!user && !!organization,
  });
}
