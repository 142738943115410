import {
  Heading,
  HStack,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Spacer,
  StyleProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FaChevronRight as NextPageIcon,
  FaChevronLeft as PrevPageIcon,
  FaCaretUp as SortAscendingIcon,
  FaCaretDown as SortDescendingIcon,
} from "react-icons/fa";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { useAppSelector } from "../hooks";
import { CloudRenderingRegion } from "../hooks/types";
import { LatencyDisplay } from "./LatencyDisplay";
interface AvailableRegionsTableParams {
  regions: CloudRenderingRegion[];
  preferredCloudRenderingRegionName?: string;
  isLoading: boolean;
  setCloudRenderingRegion(region?: string): void;
}

// see https://tanstack.com/table/v8/docs/api/core/column-def#meta
// strongly typed meta data for columns of table
declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    props?: StyleProps;
  }
}

// Define your row shape
type CloudRenderingRegionStats = {
  latency: number;
} & CloudRenderingRegion;

const columnHelper = createColumnHelper<CloudRenderingRegionStats>();

export function CloudRenderingRegionsTable({
  regions,
  preferredCloudRenderingRegionName,
  isLoading,
  setCloudRenderingRegion,
}: AvailableRegionsTableParams) {
  const { t } = useTranslation();
  const cloudRendering = useAppSelector(selectCloudRendering);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        cell: (props) => {
          return (
            <Radio
              value={props.row.original.name}
              isDisabled={!props.row.original.isEnabled}
            />
          );
        },
        meta: {
          props: { padding: 0, paddingLeft: 0.5 },
        },
      }),
      columnHelper.accessor("displayName", {
        header: () => t("streamingPreferences.map.region"),
        enableSorting: true,
        cell: (info) => {
          // if there's another region with the same name displayed in the table, append the cloud provider's name to the region name
          const displayName = info.getValue();
          const sameNameRegionExists = info.table
            .getRowModel()
            .rows.some(
              (row) =>
                row.original.name !== info.row.original.name &&
                row.original.displayName === displayName,
            );
          return (
            <Text as="b">
              {info.getValue()}
              {sameNameRegionExists && ` (${info.row.original.cloudProvider})`}
            </Text>
          );
        },
      }),
      columnHelper.accessor("distanceM", {
        header: () => t("streamingPreferences.map.distance"),
        cell: (info) => <>{(info.getValue() / 1000).toFixed()} km</>,
        enableSorting: true,
      }),
      columnHelper.accessor("latency", {
        header: () => t("streamingPreferences.map.latency"),
        cell: (info) => <LatencyDisplay latency={info.getValue()} />,
        enableSorting: true,
        sortUndefined: 1,
      }),
    ],
    [t],
  );

  const data = useMemo(
    () =>
      regions.map<CloudRenderingRegionStats>((region) => ({
        ...region,
        latency: cloudRendering.regionsLatency[region?.name],
      })),
    [regions, cloudRendering.regionsLatency],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: 5 },
      sorting: [{ desc: false, id: "latency" }], // sort by latency per default
    },
    autoResetPageIndex: false, // prevent changing the page when new data comes in.
  });

  return (
    <>
      <HStack>
        <Heading size={"sm"}>
          {t("streamingPreferences.dialog.regions")}
        </Heading>
        <Spacer />
        <Tooltip label="Previous Page">
          <IconButton
            size="sm"
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
            icon={<Icon as={PrevPageIcon} />}
            aria-label={"Previous page"}
            colorScheme="gray"
          />
        </Tooltip>
        <Tooltip label="Next Page">
          <IconButton
            size="sm"
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
            icon={<Icon as={NextPageIcon} />}
            aria-label={"Next page"}
            colorScheme="gray"
          />
        </Tooltip>
      </HStack>
      <RadioGroup
        onChange={setCloudRenderingRegion}
        value={preferredCloudRenderingRegionName}
        isDisabled={isLoading}
        minHeight={124}
        marginTop={3}
      >
        <TableContainer>
          <Table size="sm">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      {...(header.column.columnDef.meta?.props ?? {})}
                      onClick={header.column.getToggleSortingHandler()}
                      {...(header.column.getCanSort()
                        ? { cursor: "pointer", userSelect: "none" }
                        : {})}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {{
                        asc: <Icon as={SortAscendingIcon} />,
                        desc: <Icon as={SortDescendingIcon} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      {...(cell.column.columnDef.meta?.props ?? {})}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </>
  );
}
