import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import {
  useActiveOrganizationQuery,
  useAppSelector,
  useCloudRenderingRegionsQuery,
  useNotificationToast,
} from "../hooks";
import type { UserPreferences } from "../hooks/types";
import { useCloudProviders } from "../hooks/useCloudProviders";
import { useCloudRenderingRegion } from "../hooks/useCloudRenderingRegionsQuery";
import { useUserPreferences } from "../hooks/useUserPreferences";
import { setUserPreferences } from "../session-management-api";
import { CloudRenderingRegionsMap } from "./CloudRenderingRegionsMap";
import { CloudRenderingRegionsTable } from "./CloudRenderingRegionsTable";

const userPreferencesQueryKey = ["user-preferences"];

export function CloudRenderingRegionPreferences() {
  const { t } = useTranslation();
  const [enabledCloudProviders, setEnabledCloudProviders] = useState<{
    [id: string]: boolean;
  }>({});
  const { data: userPreference, isLoading: isUserPreferencesLoading } =
    useUserPreferences();
  const toast = useNotificationToast();
  const cloudRendering = useAppSelector(selectCloudRendering);
  const queryClient = useQueryClient();
  const preferredRegionDetailsQuery = useCloudRenderingRegion(
    userPreference?.preferredRenderingRegion,
  );
  const preferredRegionDetails = preferredRegionDetailsQuery.data;
  const manualMode = useMemo(
    () => !!userPreference?.preferredRenderingRegion,
    [userPreference?.preferredRenderingRegion],
  );
  const cloudProviders = useCloudProviders();
  const { data: regions, isFetched: areRegionsFetched } =
    useCloudRenderingRegionsQuery({
      appId: cloudRendering.applicationBuildId,
    });
  const defaultRegion = useMemo(
    () => (regions ?? []).filter((r) => r.isEnabled).at(0)?.name,
    [regions],
  );
  const { data: organization } = useActiveOrganizationQuery();

  // ensure all cloud providers are initially enabled
  useEffect(() => {
    setEnabledCloudProviders((enabledCloudProviders) =>
      Object.fromEntries(
        cloudProviders.map((cloudProvider) => [
          cloudProvider.name,
          enabledCloudProviders[cloudProvider.name] ?? true,
        ]),
      ),
    );
  }, [cloudProviders]);

  // get filtered list of cloud rendering regions based on disabled cloud providers
  const filteredCloudRenderingRegions = useMemo(() => {
    return regions?.filter((c) => enabledCloudProviders[c.cloudProvider]);
  }, [enabledCloudProviders, regions]);

  const updatePreferredCloudRenderingRegion = (region: string | undefined) => {
    // when switching to automated mode, re-enable all cloud providers
    region === undefined &&
      setEnabledCloudProviders(
        cloudProviders.reduce<{ [region: string]: boolean }>(
          (all, cloudProvider) => ({ ...all, [cloudProvider.name]: true }),
          {},
        ),
      );

    setPreferredCloudRenderingRegionMutation.mutate(region);
  };

  const setPreferredCloudRenderingRegionMutation = useMutation<
    UserPreferences,
    unknown,
    string | undefined
  >({
    mutationFn: (region) =>
      setUserPreferences({
        preferredRenderingRegion: region,
        organizationId: organization?.id?.toString(),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userPreferencesQueryKey });
      toast.closeAll();
      toast({
        title: t("streamingPreferences.dialog.savedTitle"),
        description: t("streamingPreferences.dialog.saved"),
        status: "success",
      });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: userPreferencesQueryKey });
      toast.closeAll();
      toast({
        title: t("streamingPreferences.dialog.errorTitle"),
        description: t("streamingPreferences.dialog.error"),
        status: "error",
      });
    },
    // optimistic update; set data in internal state before new data is here yet from the API
    onMutate: async (change) => {
      await queryClient.cancelQueries({ queryKey: userPreferencesQueryKey });

      const previousPreferences = queryClient.getQueryData(
        userPreferencesQueryKey,
      );

      queryClient.setQueryData<UserPreferences | undefined>(
        userPreferencesQueryKey,
        (old) => {
          if (old === undefined) return old;
          old.preferredRenderingRegion = change;
          return old;
        },
      );

      return { previousPreferences };
    },
  });

  const isLoading = useMemo(() => {
    return (
      setPreferredCloudRenderingRegionMutation.isPending ||
      isUserPreferencesLoading
    );
  }, [
    setPreferredCloudRenderingRegionMutation.isPending,
    isUserPreferencesLoading,
  ]);

  return (
    <Stack spacing={5}>
      <Text>{t("streamingPreferences.dialog.explanation")}</Text>
      <Button
        onClick={() => {
          return updatePreferredCloudRenderingRegion(
            manualMode ? undefined : defaultRegion,
          );
        }}
        isDisabled={isLoading}
      >
        {t(
          manualMode
            ? "streamingPreferences.dialog.automaticModeLabel"
            : "streamingPreferences.dialog.manualModeLabel",
        )}
      </Button>

      {manualMode && (
        <>
          {!preferredRegionDetails && preferredRegionDetailsQuery.isFetched && (
            <Alert status="warning">
              <AlertIcon alignSelf={"start"} />
              <Stack paddingLeft={2}>
                <AlertTitle>
                  {t("streamingPreferences.preferred_region_unavailable")}
                </AlertTitle>
                <AlertDescription>
                  {t(
                    "streamingPreferences.preferred_region_unavailable_details",
                    {
                      preferredRegion: userPreference?.preferredRenderingRegion,
                    },
                  )}
                </AlertDescription>
              </Stack>
            </Alert>
          )}
          {areRegionsFetched &&
            regions?.find(
              (region) =>
                region.name === userPreference?.preferredRenderingRegion,
            ) === undefined && (
              <Alert status="warning">
                <AlertIcon alignSelf={"start"} />
                <Stack paddingLeft={2}>
                  <AlertTitle>
                    {t("streamingPreferences.preferred_region_unsupported")}
                  </AlertTitle>
                  <AlertDescription>
                    {t(
                      "streamingPreferences.preferred_region_unsupported_details",
                      {
                        preferredRegion: preferredRegionDetails?.displayName,
                      },
                    )}
                  </AlertDescription>
                </Stack>
              </Alert>
            )}
          <FormControl>
            <FormLabel htmlFor="cloudProvider" fontWeight={"bold"}>
              {t("streamingPreferences.dialog.preferredCloudProvider")}
            </FormLabel>
            <Stack direction="row" spacing={4}>
              {cloudProviders?.map((cloudProvider) => (
                <Checkbox
                  isDisabled={isLoading}
                  key={cloudProvider.name}
                  value={cloudProvider.name}
                  isChecked={enabledCloudProviders[cloudProvider.name]}
                  onChange={(e) => {
                    setEnabledCloudProviders({
                      ...enabledCloudProviders,
                      [e.target.value]: e.target.checked,
                    });
                  }}
                >
                  {cloudProvider.displayName}
                </Checkbox>
              ))}
            </Stack>
          </FormControl>
          {/* show warning if preferred region is not available */}
          <CloudRenderingRegionsTable
            regions={filteredCloudRenderingRegions ?? []}
            preferredCloudRenderingRegionName={
              userPreference?.preferredRenderingRegion
            }
            isLoading={isLoading}
            setCloudRenderingRegion={updatePreferredCloudRenderingRegion}
          />
          <CloudRenderingRegionsMap
            regions={filteredCloudRenderingRegions}
            preferredCloudRenderingRegionName={
              userPreference?.preferredRenderingRegion
            }
            setCloudRenderingRegion={updatePreferredCloudRenderingRegion}
            isLoading={isLoading}
          />
        </>
      )}
    </Stack>
  );
}
