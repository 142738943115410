import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import cloudRenderingReducer from "./features/cloudRenderingSlice";
import desktopClientReducer from "./features/desktopClientSlice";
import devicesReducer, {
  persistActiveDeviceMiddleware,
} from "./features/devicesSlice";
import interactiveSpectatorReducer from "./features/interactiveSpectatorSlice";
import notificationReducer from "./features/notificationSlice";
import sessionReducer from "./features/sessionSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    devices: devicesReducer,
    session: sessionReducer,
    desktopClient: desktopClientReducer,
    cloudRendering: cloudRenderingReducer,
    interactiveSpectator: interactiveSpectatorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([persistActiveDeviceMiddleware]),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat([sentryReduxEnhancer]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
