import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { selectHasNonEndedSession } from "../features/sessionSlice";
import { useAppSelector, useNotificationToast } from "../hooks";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import {
  useAllowedWavelengthZoneNames,
  useCloudRenderingRegion,
  useCloudRenderingRegionsQuery,
} from "../hooks/useCloudRenderingRegionsQuery";
import { useUserPreferences } from "../hooks/useUserPreferences";
import { SessionData } from "../session/types";
import { generateAppLaunchLink } from "../utils/routes";
import { SessionErrorDialog } from "./SessionErrorDialog";

export const RegionNoCapacityWarning = ({
  erroredSessionData,
  ...dialogProps
}: {
  erroredSessionData: SessionData;
} & Omit<AlertDialogProps, "children" | "leastDestructiveRef">) => {
  const toast = useNotificationToast();
  const { t } = useTranslation();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { data: userPreference, isSuccess: userPreferenceIsSuccess } =
    useUserPreferences();
  // find the next best region since the best does not have any capacity
  const currentRegionQuery = useCloudRenderingRegion(
    erroredSessionData.renderRegion,
  );
  const currentRegion = currentRegionQuery.data;
  const isAutomaticRegionSelection = !userPreference?.preferredRenderingRegion;
  const { data: applicationBuild, isSuccess: applicationBuildQueryIsSuccess } =
    useApplicationBuildQuery(erroredSessionData.applicationBuildId);
  const cloudRendering = useAppSelector(selectCloudRendering);
  const nextBestRegionsQuery = useCloudRenderingRegionsQuery(
    {
      appId: applicationBuild?.id,
      latencyInfo: cloudRendering.regionsLatency,
      preferredRegion: userPreference?.preferredRenderingRegion,
      allowWavelengthZones: true,
      allowedWavelengthZones: useAllowedWavelengthZoneNames(),
    },
    {
      enabled: userPreferenceIsSuccess && applicationBuildQueryIsSuccess,
      // only consider regions that are enabled
      select: (regions) => regions.filter((r) => r.isEnabled),
    },
  );
  const previousSessionStillRunning = useSelector(selectHasNonEndedSession);
  const nextBestRegion = nextBestRegionsQuery.data?.[0];
  let alternativeRegionDisplayName =
    nextBestRegion?.displayName ?? t("launch.unknown_region");
  let preferredRegionDisplayName =
    currentRegion?.displayName ?? t("launch.unknown_region");
  if (
    nextBestRegion &&
    currentRegion &&
    alternativeRegionDisplayName === preferredRegionDisplayName
  ) {
    alternativeRegionDisplayName += ` (${nextBestRegion.cloudProvider})`;
    preferredRegionDisplayName += ` (${currentRegion.cloudProvider})`;
  }
  const onClose = dialogProps.onClose;
  const navigate = useNavigate();
  const relaunchLink = useMemo(
    () =>
      nextBestRegion &&
      applicationBuild &&
      generateAppLaunchLink({
        appId: applicationBuild?.application,
        type: erroredSessionData.type,
        renderRegion: nextBestRegion.name,
        vmSize: erroredSessionData.vmSize,
        vmImage: erroredSessionData.vmImage,
        launchArguments: erroredSessionData.extraLaunchArguments,
        encryptVrStream: erroredSessionData.cloudXREncryption,
      }),
    [
      applicationBuild,
      erroredSessionData.cloudXREncryption,
      erroredSessionData.extraLaunchArguments,
      erroredSessionData.type,
      erroredSessionData.vmImage,
      erroredSessionData.vmSize,
      nextBestRegion,
    ],
  );

  const onAccept = useCallback(() => {
    if (!relaunchLink) return;

    onClose();
    navigate(relaunchLink);
  }, [navigate, onClose, relaunchLink]);

  // in case the user is in automatic region selection mode, we can just try again in the next best region but INFORM the user about the change
  useEffect(() => {
    // wait for the previous session to be closed
    if (previousSessionStillRunning) return;

    if (isAutomaticRegionSelection && relaunchLink) {
      onAccept();
      toast({
        title: t("launch.region_no_capacity"),
        description: t("launch.region_no_capacity_redirect_detail", {
          alternativeRegion: alternativeRegionDisplayName,
          preferredRegion: preferredRegionDisplayName,
        }),
        status: "info",
        duration: null,
        isClosable: true,
      });
    }
  }, [
    toast,
    t,
    onAccept,
    isAutomaticRegionSelection,
    relaunchLink,
    preferredRegionDisplayName,
    previousSessionStillRunning,
    alternativeRegionDisplayName,
  ]);

  if (
    !erroredSessionData.error?.sessionId ||
    !erroredSessionData.error?.message
  ) {
    return null;
  }

  // if there is no next best region, we can't do anything
  if (nextBestRegionsQuery.isFetched && !nextBestRegion) {
    return (
      <SessionErrorDialog
        sessionId={erroredSessionData.error?.sessionId}
        errorMessage={erroredSessionData.error?.message}
        {...dialogProps}
      />
    );
  }

  // in case the user is in automatic region selection mode, we can just try again in the next best region but INFORM the user
  if (isAutomaticRegionSelection) {
    // handled in the effect above
    return null;
  }

  // otherwise, we need to prompt the user to try again in the next best region (or to select any other region)
  return (
    <AlertDialog
      isCentered
      leastDestructiveRef={cancelRef}
      size={"2xl"}
      {...dialogProps}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("launch.region_no_capacity")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t("launch.region_no_capacity_detail", {
              preferredRegion: preferredRegionDisplayName,
            })}{" "}
            {nextBestRegionsQuery.isLoading
              ? t("launch.region_no_capacity_loading")
              : t("launch.region_no_capacity_alternative", {
                  alternativeRegion: alternativeRegionDisplayName,
                })}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={nextBestRegionsQuery.isError ? cancelRef : undefined}
              colorScheme="gray"
              onClick={dialogProps.onClose}
            >
              {t("launch.cancel")}
            </Button>
            {!nextBestRegionsQuery.isError && (
              <Button
                ref={cancelRef}
                isLoading={nextBestRegionsQuery.isLoading}
                colorScheme="green"
                onClick={onAccept}
                ml={3}
              >
                {t("launch.continue", {
                  regionName: alternativeRegionDisplayName,
                })}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
