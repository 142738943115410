import { useQuery } from "@tanstack/react-query";
import { getOrganizationCloudRenderingPolicies } from "../session-management-api";
import {
  getQueryKeyForOrganization,
  useActiveOrganizationQuery,
} from "./useActiveOrganizationQuery";

export function useOrganizationCloudRenderingPolicies() {
  const { data: organization, isSuccess } = useActiveOrganizationQuery();

  return useQuery({
    queryKey: [...getQueryKeyForOrganization(organization?.id), "policies"],
    queryFn: () => getOrganizationCloudRenderingPolicies(organization?.id ?? 0),
    enabled: isSuccess,
  });
}
