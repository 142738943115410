import log from "loglevel";
import { useEffect } from "react";
import { useAuthentication } from "../auth";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector, useCloudRenderingRegionsQuery } from "../hooks";
import {
  sendSessionReady as sendSessionReadySignalR,
  setToken,
} from "../signalR";
import { SESSION_STATE } from "./session-state";
import { SessionType } from "./types";

export function CloudRenderingSessionProvider() {
  const { token } = useAuthentication();
  const session = useAppSelector(selectSessionState);

  // ensure we load the regions at least once
  useCloudRenderingRegionsQuery();

  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  useEffect(() => {
    if (
      session.type === SessionType.CloudRenderedNonVR &&
      session.state === SESSION_STATE.READY
    ) {
      log.debug("Cloud session (Browser) ready");
      sendSessionReadySignalR(session.id!);
    }
  }, [session]);

  return null;
}
